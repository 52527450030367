<script>
export default {
    props: {
        url: {
            type: String,
            required: true,
        },
        translator: {
            type: Function,
            required: true,
        },
    },
    methods: {
        goLive() {
            window.location.href = this.url
        },
    },
}
</script>

<template>
    <!-- MESSAGE -->
    <div :class="$style.toast">
        <p>
            {{ translator('live-running') }}
            <BaseButton :class="$style.messageButton" @click.native="goLive">
                {{ translator('go-to-class') }}
            </BaseButton>
        </p>
        <!-- <a :href="url">
            {{ translator('go-to-class') }}
        </a> -->
    </div>
</template>

<style lang="scss" module>
@import '@design';
.messageButton {
    margin-left: 10px;
    margin-right: 10px;
    margin-top: 20px;
}
.toast {
    font-size: 20px;
    a {
        color: white;
    }
}
</style>
