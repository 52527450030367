var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('Layout',[_c('BaseHeader'),_c('div',{class:_vm.$style.menu},[(_vm.displayTabs.comingSoon || _vm.displayTabs.today)?_c('BaseMenu',[(_vm.displayTabs.today)?_c('BaseMenuLink',{attrs:{"to":"sessions.today","secondary":"","active":this.$route.meta.filter === 'today'}},[_vm._v(" "+_vm._s(_vm.$t('today'))+" ")]):_vm._e(),(_vm.displayTabs.comingSoon)?_c('BaseMenuLink',{attrs:{"to":"sessions.coming-soon","secondary":"","active":this.$route.meta.filter === 'comingSoon'}},[_vm._v(" "+_vm._s(_vm.$t('coming-soon'))+" ")]):_vm._e(),(_vm.displayTabs.past)?_c('BaseMenuLink',{attrs:{"to":"sessions.past","secondary":"","active":this.$route.meta.filter === 'past'}},[_vm._v(" "+_vm._s(_vm.$t('past'))+" ")]):_vm._e(),(_vm.displayTabs.all)?_c('BaseMenuLink',{attrs:{"to":"sessions.all","secondary":"","active":this.$route.meta.filter === 'all'}},[_vm._v(" "+_vm._s(_vm.$t('all'))+" ")]):_vm._e()],1):_vm._e()],1),_c('main',{class:_vm.$style.main},[_c('BaseContainer',[(Object.keys(_vm.ordoredSessions[_vm.$route.meta.filter]).length)?_vm._l((Object.keys(_vm.ordoredSessions[_vm.$route.meta.filter]).reverse()),function(year,indexYear){return _c('BaseTable',{key:year,class:_vm.$style.table,attrs:{"title":year,"collapsable":"","collapsed":indexYear !== 0}},[_c('BaseTableBody',{attrs:{"data":_vm.ordoredSessions[_vm.$route.meta.filter][year]},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var session = ref.item;
return [_c('BaseLink',{attrs:{"to":_vm.$theme !== session.theme
                                        ? null
                                        : {
                                              name: 'session.map',
                                              params: { id: session.id },
                                          },"href":_vm.$theme !== session.theme ? _vm.getRedirectionURL(session) : null,"tag":"th"}},[_c('ListContent',[_c('ListImage',{attrs:{"image":session.competition.concour.logo,"isLogo":true}}),_c('ListTitles',{scopedSlots:_vm._u([{key:"subtext",fn:function(){return [_c('div',{class:_vm.$style.subtext},[_c('span',[_vm._v(" "+_vm._s(((_vm.getDateAndTime(session.begin_at).date) + " " + (_vm.$t('at')) + " " + (_vm.getDateAndTime(session.begin_at).time)))+" ")]),(session.is_live)?_c('BaseBadge',{attrs:{"text":"Live","color":_vm.badgeColors.main,"text-color":_vm.badgeColors.text,"pulse":true}}):_vm._e()],1)]},proxy:true}],null,true)},[_vm._v(" "+_vm._s(session.name)+" ")])],1)],1)]}}],null,true)})],1)}):_c('BaseEmpty',[_c('div',[_vm._v(" "+_vm._s(_vm.$t('no-session'))+" ")]),_c('BaseButton',{class:_vm.$style.messageButton,attrs:{"to":{ name: 'sessions' }}},[_vm._v(" "+_vm._s(_vm.$t('back-to-classes'))+" ")])],1)],2)],1),_c('BaseFooter')],1)}
var staticRenderFns = []

export { render, staticRenderFns }