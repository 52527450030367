<script>
import { mixin as clickaway } from 'vue-clickaway'

export default {
    name: 'BaseModal',
    mixins: [clickaway],
    props: {
        title: {
            type: String,
            default: '',
        },
    },
    data() {
        return {
            open: false,
        }
    },
    methods: {
        toggle() {
            this.open = !this.open
        },
    },
}
</script>

<template>
    <Portal to="modal">
        <transition
            :enter-class="$style.opacityEnter"
            :enter-active-class="$style.opacityEnterActive"
            :leave-active-class="$style.opacityLeaveActive"
            :leave-to-class="$style.opacityLeaveTo"
        >
            <div v-if="open" :class="[$style.wrapper, $style.overlay]" />
        </transition>
        <transition
            :enter-class="$style.slideEnter"
            :enter-active-class="$style.slideEnterActive"
            :leave-active-class="$style.slideLeaveActive"
            :leave-to-class="$style.slideLeaveTo"
        >
            <div v-if="open" :class="$style.wrapper">
                <div :class="$style.table">
                    <div :class="$style.cell">
                        <div v-on-clickaway="toggle" :class="$style.modal">
                            <div :class="$style.header">
                                <div :class="$style.closeButton" @click="toggle">
                                    <div :class="$style.closeCross" />
                                </div>
                                <div v-if="title" :class="$style.title">
                                    {{ title }}
                                </div>
                            </div>
                            <div :class="$style.modalBody">
                                <slot />
                            </div>
                            <div v-if="$slots.footer && $slots.footer[0].tag" :class="$style.modalFooter">
                                <slot name="footer" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </transition>
    </Portal>
</template>

<style lang="scss" module>
@import '@design';

.wrapper {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 9999;

    @include tablet {
        overflow-y: auto;
    }

    @include mobile {
        overflow-y: hidden;
        pointer-events: none;
        background: none;
        transform: translate3d(0, 0, 0);
    }
}
.overlay {
    @include tablet {
        background: rgba(black, 0.9);
    }

    @include mobile {
        display: none;
    }
}
.table {
    @include tablet {
        display: table;
        width: 100%;
        height: 100%;
    }

    @include mobile {
        position: relative;
        width: 100%;
        height: 100%;
        overflow-y: hidden;
        pointer-events: auto;
        will-change: transform;
    }
}
.cell {
    @include overflow-touch;

    @include tablet {
        display: table-cell;
        padding: 64px;
        vertical-align: middle;
    }

    @include mobile {
        position: absolute;
        top: 0;
        bottom: 0;
        width: 100%;
        overflow-y: auto;
        background-color: var(--color-background);
    }
}
.modal {
    width: 100%;
    margin: auto;

    @include tablet {
        position: relative;
        max-width: 450px;
        background-color: var(--color-background);
        border-radius: $border-radius;
    }

    @include mobile {
        height: 100%;
        padding: 20px;
    }
}
.header {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    height: 40px;
    background: rgba(0, 0, 0, 0.2);
    .title {
        position: absolute;
        width: 100%;
        text-align: center;
        opacity: 0.6;
    }
    .closeButton {
        position: relative;
        z-index: 5;
        display: inline-block;
        margin: 0 10px;
        cursor: pointer;
    }
    .closeCross {
        position: relative;
        display: block;
        width: 16px;
        height: 16px;
        font-size: 0;
        cursor: pointer;
        &::after,
        &::before {
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            height: 2px;
            margin: auto;
            content: '';
            background: var(--color-text);
            border-radius: 3px;
            transform: rotate(45deg);
        }
        &::after {
            transform: rotate(-45deg);
        }
    }
}
.modalBody {
    padding: 20px;
}
.modalFooter {
    display: flex;
    justify-content: flex-end;
    padding: 20px;
    border-top: 1px solid var(--color-border);
}

.slideEnterActive,
.slideLeaveActive {
    transition: all 0.3s ease;
}
.slideEnter,
.slideLeaveTo {
    transform: translate3d(0, 100%, 0);
}
.opacityEnterActive,
.opacityLeaveActive {
    transition: all 0.3s ease;
}
.opacityEnter,
.opacityLeaveTo {
    opacity: 0;
}
</style>
