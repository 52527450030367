<script>
export default {
    props: {
        animate: {
            type: Boolean,
            default: false,
        },
    },
}
</script>

<template>
    <g :class="[$style.checkerboard, animate ? $style.animation : '']">
        <rect width="4" height="2" />

        <rect x="2" y="1" width="1" height="1" :class="$style.white" />
        <rect y="1" width="1" height="1" :class="$style.white" />
        <rect x="1" width="1" height="1" :class="$style.white" />
        <rect x="3" width="1" height="1" :class="$style.white" />
    </g>
</template>

<style lang="scss" module>
@import '@design';

.checkerboard {
    .white {
        fill: var(--color-text-white);
    }

    rect:first-child {
        stroke: var(--color-background-dark);
        stroke-width: 0.3;
    }
}

.animation {
    animation: shake 1s both; // cubic-bezier(0.36, 0.07, 0.19, 0.97)
}

@keyframes shake {
    10%,
    90% {
        transform: translate3d(-1px, 0, 0);
    }

    20%,
    80% {
        transform: translate3d(1.5px, 0, 0);
    }

    30%,
    50%,
    70% {
        transform: translate3d(-2px, 0, 0);
    }

    40%,
    60% {
        transform: translate3d(2px, 0, 0);
    }
}
</style>
