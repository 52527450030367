import Vue from 'vue'

const requireModule = require.context(
    // Search for files in the current directory.
    '.',
    // Search for files in subdirectories.
    false,
    // Include any .js files that are not this file or a unit test.
    /^((?!index|\.unit\.).)*\.js$/
)

requireModule.keys().forEach((fileName) => {
    if (fileName === './index.js') return
    const name = fileName.replace(/^.*(\\|\/|:)/, '').split('.')[0]
    Vue.directive(name, requireModule(fileName).default)
})
