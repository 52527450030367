<script>
export default {
    props: {
        type: {
            type: String,
            default: '',
        },
        to: {
            type: Object,
            default: null,
        },
    },
}
</script>

<template>
    <BaseLink v-if="to" :to="to" :class="[$style.button, type === 'full' ? $style.typeFull : '']" tag="div">
        <slot />
    </BaseLink>
    <div v-else :class="[$style.button, type === 'full' ? $style.typeFull : '']">
        <slot />
    </div>
</template>

<style lang="scss" module>
@import '@design';

.button {
    position: relative;
    display: inline-flex;
    align-items: center;
    height: 30px;
    padding: 0 10px;
    font-size: 10px;
    font-weight: 500;
    text-transform: uppercase;
    cursor: pointer;
    background: rgba(255, 255, 255, 0.08);
    border: 1px solid rgba(255, 255, 255, 0);
    border-radius: 5px;
    opacity: 0.5;
    transition: background 0.1s ease-in-out, color 0.1s ease-in-out;
    span {
        color: white;
    }
    &:not(:first-child) {
        margin-left: 5px;
    }
    &:hover {
        color: white;
        opacity: 1;
    }
    &:active {
        background: rgba(0, 0, 0, 0.3);
        border: 1px solid rgba(255, 255, 255, 0.3);
        opacity: 1;
    }
    svg {
        width: 20px;
        height: 20px;
        fill: white;
    }
    svg + span {
        margin-left: 5px;
    }
    // Types
    &.typeFull {
        display: flex;
        margin-left: 0;
        &:not(:first-child) {
            margin-top: 5px;
        }
    }
}
</style>
