<template>
    <div :class="$style.header">
        <!-- LOGO -->
        <div :class="$style.logoWrapper">
            <BaseLink
                :href="
                    !this.$ignoreConcoursLogo &&
                    $route.name.includes('sessions') &&
                    session.competition &&
                    session.competition.concour.logo_url
                        ? session.competition.concour.logo_url
                        : this.$logoUrl
                "
                :class="$style.logo"
            >
                <div :class="$style.logoImg">
                    <img
                        v-if="this.$theme === 'rgs' || $route.name.includes('sessions')"
                        :src="
                            this.$ignoreConcoursLogo ||
                            $route.name.includes('sessions') ||
                            'competition' in session === false
                                ? require(`@assets/images/${this.$logo}.png`)
                                : session.competition.concour.logo
                        "
                        alt=""
                        class="main-logo"
                    />
                    <ListImage v-else :image="session.competition.concour.logo" :isLogo="true" />
                </div>

                <div v-if="session.name && !$route.name.includes('sessions')" :class="$style.eventTitle">
                    <div v-for="(partName, indexPartName) in session.name.split('-')" :key="indexPartName">
                        {{ partName.trim() }}
                    </div>
                </div>
            </BaseLink>
        </div>
        <!-- MENU -->
        <slot />
    </div>
</template>

<script>
import { mapState } from 'vuex'
import ListImage from '@components/list-image'

export default {
    components: {
        ListImage,
    },
    data() {
        return {}
    },
    computed: {
        ...mapState('session', {
            session: (state) => state.session,
        }),
    },
}
</script>

<style lang="scss" module>
@import '@design';

.header {
    overflow: hidden;
    background: var(--color-background-secondary);
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}
.logoWrapper {
    display: flex;
    justify-content: space-around;
    margin-top: 10px;
    margin-bottom: 10px;
    min-height: 65px;
}
.logoImg {
    margin: auto;
}
.logo {
    display: flex;
    justify-content: center;
}
.logoBaseline {
    margin-left: 5px;
    font-size: 18px;
    font-weight: 700;
}
.eventLogo {
    margin-left: 10px;
}
.eventTitle {
    line-height: 15px;
    font-size: smaller;
    margin: auto;
    margin-left: 10px;
    text-transform: uppercase;
    // text-align: center;
    color: var(--color-secondary);
}
</style>
