<template>
    <div :class="$style.wrapper">
        <div :class="$style.pie">
            <svg>
                <circle
                    v-for="(item, key) in dataObjects"
                    :key="key"
                    :style="{
                        strokeDasharray: `${item.relativeSize} ${circleLength}`,
                        strokeDashoffset: item.offset,
                        stroke: chartData.colors[key],
                    }"
                    r="25%"
                    cx="50%"
                    cy="50%"
                />
            </svg>
        </div>

        <div :class="$style.legend">
            <ul>
                <li
                    v-for="(label, key) in chartData.labels"
                    :key="label"
                    :style="{ color: chartData.colors[key], display: 'flex' }"
                >
                    <svg width="20" height="20">
                        <rect x="10" y="10" width="10" height="10" :style="{ fill: chartData.colors[key] }" />
                    </svg>

                    <span :class="$style.label">{{ label }}</span>
                </li>
            </ul>
        </div>
    </div>
</template>

<script>
/* 
chartData props object exemple :
    {
        values: [10, 20, 40, 50, 5, 12],
        labels: ['oxer', 'triple', 'vertical', 'palank', 'wall', 'liverpool']
    }
*/
export default {
    name: 'PieChart',
    props: {
        chartData: {
            type: Object,
            required: true,
        },
        circleLength: {
            type: Number,
            default: 372,
        },
    },
    computed: {
        dataTotal() {
            return this.chartData.values.reduce((previous, current) => previous + current)
        },
        dataObjects() {
            let startingPoint = 0
            return this.chartData.values.map((item) => {
                let relativeSize = (item / this.dataTotal) * this.circleLength
                let dataObject = { relativeSize: relativeSize, offset: -startingPoint }
                startingPoint += relativeSize
                return dataObject
            })
        },
    },
}
</script>

<style lang="scss" module>
@import '@design';

.wrapper {
    display: table;
}
.legend {
    display: table-cell;
    margin-left: -30px;
    vertical-align: middle;

    ul {
        display: table-cell;
    }
}
.label {
    margin-top: 7px;
    margin-left: 7px;
}
.pie {
    width: 240px;

    circle {
        fill: none;
        stroke-width: 32;
    }
}
</style>
