<script>
import { mapGetters, mapState } from 'vuex'
import decimal from '@components/filters/decimal'

import ListCompetitor from '@components/list-competitor'

export default {
    components: {
        ListCompetitor,
    },
    data() {
        return {
            statistics: [],
        }
    },
    computed: {
        ...mapGetters('session', ['outOfTimeCompetitors', 'pastCompetitors']),
        ...mapState('session', {
            session: (state) => state.session,
        }),
        outOfTimeCompetitorsPercent() {
            return decimal((this.outOfTimeCompetitors.length * 100) / this.pastCompetitors.length, 0)
        },
    },
}
</script>

<template>
    <!-- <div v-if="statistics.length && statistics.competitorsTimeExceeded" :class="$style.exceededTime"> -->
    <div v-if="this.pastCompetitors.length > 0" :class="$style.wrapper">
        <div :class="$style.statText">
            <span
                v-html="
                    $tc('competitors-out-of-time-txt-number', outOfTimeCompetitors.length, {
                        count: outOfTimeCompetitors.length,
                        maxTime: session.maxtime,
                    }) + '. '
                "
            />
            <span
                v-html="
                    $tc('competitors-out-of-time-txt-percent', outOfTimeCompetitors.length, {
                        percent: outOfTimeCompetitorsPercent,
                    }) + '.'
                "
            />
        </div>

        <ListCompetitor
            v-for="(competitor, key) in outOfTimeCompetitors"
            :key="`competitor${key}`"
            :competitor="competitor"
            :stats="true"
            :maxTime="session.maxtime"
            :odd="key % 2 === 0"
            :qualified="
                parseInt(competitor.status) === 1 &&
                    competitor.time !== null &&
                    ((session.qualified_mode === 'no-faults' && competitor.totalFaults === 0) ||
                        (session.qualified_mode === 'x-first' && key < session.qualified_count))
            "
        />
    </div>
    <BaseContainer v-else>
        <BaseEmpty> {{ $t('no-stats') }}. </BaseEmpty>
    </BaseContainer>
</template>

<style lang="scss" module>
.wrapper {
    margin-top: 10px;
}
.statTitleList {
    margin-bottom: 7px;
    font-size: 0.9em;
    opacity: 0.8;
    font-weight: 400;
}
.statText {
    margin-bottom: 15px;
    font-size: 0.9em;
    opacity: 0.8;
    font-weight: 400;
}
</style>
