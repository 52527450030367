<script>
export default {
    props: {
        session: {
            type: Object,
            required: true,
        },
    },
}
</script>

<template>
    <BaseContainer>
        <div :class="$style.text">
            {{ $t('ghost-riders-txt') }}
        </div>

        <div :class="$style.video">
            <BaseVideo :src="session.video_src" />
        </div>
    </BaseContainer>
</template>

<style lang="scss" module>
.text {
    margin-top: 10px;
    margin-bottom: 13px;
    font-size: 0.9em;
    opacity: 0.8;
    font-weight: 400;
}

.video {
    max-width: 1080px;
    width: 100%;
    align-self: center;
}
</style>
