<script>
export default {
    props: {
        knockedJump: {
            type: Object,
            required: true,
        },
        odd: {
            type: Boolean,
            default: false,
        },
    },
}
</script>

<template>
    <div :class="[$style.wrapper, odd ? $style.odd : '']">
        <!-- Name -->
        <div :class="$style.name">
            <div :class="$style.nameRank">
                {{ knockedJump.name }}
            </div>
        </div>
        <!-- RIGHT -->
        <div :class="$style.right">
            <div :class="$style.results">
                <!-- Count -->
                <div :class="$style.resultsGroup">
                    <div :class="$style.resultsTitle"> {{ $tc('faults', 2) }} </div>
                    <div :class="$style.resultsContent">
                        {{ knockedJump.knockedCount }}
                    </div>
                </div>
                <!-- Percent -->
                <div :class="$style.resultsGroup">
                    <div :class="$style.resultsTitle"> {{ $t('rate') }} </div>
                    <div :class="$style.resultsContent"> {{ knockedJump.knockedPercent }}% </div>
                </div>
                <!-- Type -->
                <div :class="$style.resultsGroup">
                    <div :class="$style.resultsTitle"> {{ $t('type') }} </div>
                    <div :class="$style.resultsContent">
                        {{ knockedJump.type }}
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<style lang="scss" module>
@import '@design';

.wrapper {
    @include unselectable;

    display: flex;
    align-items: center;
    padding: 4px;
    margin: 0 -4px;
    border-radius: $border-radius;
    height: 60px;
    &:not(:last-child) {
        margin-bottom: 5px;
    }
}
.wrapperSelected {
    background: var(--color-background-hover);
}
.odd {
    background: var(--color-background-tertiary);
}
// Photo
.name {
    position: relative;
}
.nameRank {
    position: absolute;
    top: -24px;
    left: 0;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 48px;
    height: 48px;
    font-size: 18px;
    font-weight: 700;
    color: var(--color-text-input);
    background: transparent;
    border-radius: $border-radius-round;
    // box-shadow: 0 0 3px 0 var(--color);
    border: 2px solid var(--color);
}
// Right
.right {
    display: flex;
    flex: 1;
    justify-content: flex-end;
    margin-left: 13px;
    height: 35px;
}
.results {
    display: flex;
    height: 100%;
}
.resultsGroup {
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: center;
    padding: 0 10px;
    width: 80px;
}
.resultsTitle {
    margin-bottom: 3px;
    font-size: 0.6em;
    font-weight: 600;
    text-transform: uppercase;
    opacity: 0.8;
}
.resultsContent {
    font-size: 1.2em;
    font-weight: 800;
}
</style>
