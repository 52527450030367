<script>
export default {
    props: {
        to: {
            type: String,
            default: null,
        },
        secondary: {
            type: Boolean,
            default: false,
        },
        href: {
            type: String,
            default: null,
        },
        active: {
            type: Boolean,
            default: false,
        },
    },
}
</script>

<template>
    <BaseLink
        tag="div"
        :to="href ? null : { name: to }"
        :href="href ? href : null"
        :class="[
            secondary ? $style.secondary : $style.primary,
            active && secondary ? $style.activeSecondary : active ? $style.activePrimary : '',
        ]"
        @click="$emit('cliked')"
    >
        <slot />
    </BaseLink>
</template>

<style lang="scss" module>
@import '@design';

.button {
    display: inline-flex;
    align-self: center;
    padding: 8px 12px;
    font-size: 10px;
    text-align: center;
    text-transform: uppercase;
    cursor: pointer;
    font-weight: var(--button-font-weight);
}
.active {
    border-radius: $border-radius;
}

.primary {
    @extend .button;
    color: var(--color-secondary);
}
.secondary {
    @extend .button;
    color: var(--color-text-tab);
}
.activePrimary {
    @extend .active;
    color: var(--color-active);
    background: rgba(white, 0.05);
}
.activeSecondary {
    @extend .active;
    color: var(--color-active-light);
    background: var(--color-background-active);
}
</style>
