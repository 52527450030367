<script>
import Vue from 'vue'

import { mapGetters, mapState } from 'vuex'

import ListJump from '@components/list-jump'
import PieChart from '@components/pie-chart'

export default {
    name: 'KnockedJumps',
    components: {
        ListJump,
        PieChart,
    },
    data() {
        return {
            statistics: [],
            colors: Vue.prototype.$colors,
        }
    },
    computed: {
        ...mapGetters('session', ['knockedJumps', 'mostKnockedJumps']),
        ...mapState('session', {
            session: (state) => state.session,
        }),
        mostKnockedJump() {
            return this.mostKnockedJumps(1)
        },
        knockedJumpsByType() {
            let knockedJumpsByType = {}

            this.knockedJumps.forEach((knockedJump) => {
                if (knockedJump.type) {
                    if (!Array.isArray(knockedJumpsByType[knockedJump.type])) knockedJumpsByType[knockedJump.type] = []

                    knockedJumpsByType[knockedJump.type].push(knockedJump.name.toString())
                }
            })

            return knockedJumpsByType
        },
        pieChartData() {
            let countKnockedJumpsByType = []

            Object.values(this.knockedJumpsByType).forEach((typeOfKnockedJumps) => {
                countKnockedJumpsByType.push(typeOfKnockedJumps.length)
            })

            return {
                values: countKnockedJumpsByType,
                labels: Object.keys(this.knockedJumpsByType),
                // values: [10, 20, 40, 50, 10], // Test
                // labels: [10, 20, 40, 50, 10], // Test
                colors: this.colors,
            }
        },
    },
}
</script>

<template>
    <div v-if="knockedJumps.length" :class="$style.obstacles">
        <div v-if="Object.keys(knockedJumpsByType).length" :class="$style.pieChart">
            <PieChart :chartData="pieChartData" />
        </div>

        <div :class="$style.statTitle">
            <div
                v-html="
                    $tc('most-knocked-jumps-txt', knockedJumps[0].knockedCount, {
                        name: mostKnockedJump.map((knockedJump) => knockedJump.name).join(', '),
                        count: knockedJumps[0].knockedCount,
                    }) + '. '
                "
            />
            <div :class="$style.listTxt"
                >{{ $t('most-knocked-jumps-list') }}
                :
            </div>
        </div>

        <ListJump
            v-for="(knockedJump, key) in knockedJumps"
            :key="`jump-${key}`"
            :knockedJump="knockedJump"
            :odd="key % 2 === 0"
        />
    </div>
    <BaseContainer v-else>
        <BaseEmpty> {{ $t('no-stats') }}. </BaseEmpty>
    </BaseContainer>
</template>

<style lang="scss" module>
@import '@design';

.statTitle {
    margin-bottom: 7px;
    margin-top: 10px;
    font-size: 0.9em;
    opacity: 0.8;
    font-weight: 400;
}
.listTxt {
    margin-top: 15px;
    margin-bottom: 15px;
}
.pieChart {
    margin: 20px 0 20px -30px;
}
</style>
