var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:_vm.$style.header},[_c('div',{class:_vm.$style.logoWrapper},[_c('BaseLink',{class:_vm.$style.logo,attrs:{"href":!this.$ignoreConcoursLogo &&
                _vm.$route.name.includes('sessions') &&
                _vm.session.competition &&
                _vm.session.competition.concour.logo_url
                    ? _vm.session.competition.concour.logo_url
                    : this.$logoUrl}},[_c('div',{class:_vm.$style.logoImg},[(this.$theme === 'rgs' || _vm.$route.name.includes('sessions'))?_c('img',{staticClass:"main-logo",attrs:{"src":this.$ignoreConcoursLogo ||
                        _vm.$route.name.includes('sessions') ||
                        'competition' in _vm.session === false
                            ? require(("@assets/images/" + (this.$logo) + ".png"))
                            : _vm.session.competition.concour.logo,"alt":""}}):_c('ListImage',{attrs:{"image":_vm.session.competition.concour.logo,"isLogo":true}})],1),(_vm.session.name && !_vm.$route.name.includes('sessions'))?_c('div',{class:_vm.$style.eventTitle},_vm._l((_vm.session.name.split('-')),function(partName,indexPartName){return _c('div',{key:indexPartName},[_vm._v(" "+_vm._s(partName.trim())+" ")])}),0):_vm._e()])],1),_vm._t("default")],2)}
var staticRenderFns = []

export { render, staticRenderFns }