<script>
import ListImage from '@components/list-image'
// Constants
import { constantsCompetitorStatus } from '@constants/competitor'
import countryISOmapping from '@utils/countryISOmapping'
import decimal from '@components/filters/decimal'
import { getPreviousFaults } from '@utils/faults'
import { containsEmoji } from '@utils/text'

export default {
    components: {
        ListImage,
    },
    props: {
        competitor: {
            type: Object,
            required: true,
        },
        selected: {
            type: Boolean,
            default: false,
        },
        rank: {
            type: Number,
            required: false,
        },
        stats: {
            type: Boolean,
            default: false,
        },
        maxTime: {
            type: Number,
            required: false,
        },
        odd: {
            type: Boolean,
            default: false,
        },
        qualified: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        countryCode() {
            return this.competitor.country ? countryISOmapping(this.competitor.country) : ''
        },
        status() {
            return constantsCompetitorStatus.find((status) => status.value === this.competitor.status)
        },
        timeDiff() {
            return this.maxTime ? '+' + decimal(this.competitor.time - this.maxTime) + 's' : ''
        },
        jumpFaults() {
            let jumpFaults = []

            this.competitor.jumps.forEach((jump) => {
                if (jump.status.some((status) => parseInt(status) === 1)) {
                    if (jump.name) jumpFaults.push(jump.name)
                }
            })

            return jumpFaults.length ? '(' + jumpFaults.join(' ') + ')' : ''
        },
        previousFaults() {
            return getPreviousFaults(this.competitor)
        },
    },
    methods: {
        containsEmoji,
    },
}
</script>

<template>
    <div :class="[$style.wrapper, selected ? $style.wrapperSelected : '', odd ? $style.odd : '']">
        <!-- PHOTO -->
        <div :class="$style.photo">
            <!-- QUALIFIED -->
            <div v-if="qualified" :class="$style.qualified" />

            <ListImage :image="competitor.photo" :class="$style.photoImage" />
            <div v-if="rank" :class="$style.photoRank">
                {{ rank }}
            </div>
            <img
                :src="'https://flagcdn.com/16x12/' + countryCode.toLowerCase() + '.png'"
                onerror="this.style.display='none'"
                :class="$style.country"
            />
        </div>
        <!-- NAMES -->
        <div :class="$style.names">
            <div :class="$style.name">
                <div
                    v-if="containsEmoji(competitor.name)"
                    :content="`${$t('live-contenders-explanation')} <br> ${$t('bonus-contenders-explanation')}`"
                    v-tippy="{ placement: 'top-end', arrow: false, animation: 'scale', maxWidth: 450 }"
                >
                    {{ competitor.name }}
                </div>
                <div v-else>
                    {{ competitor.name }}
                </div>
            </div>
            <div :class="$style.nameHorse">
                {{ competitor.horseName }}
            </div>
        </div>
        <!-- RIGHT -->
        <div :class="$style.right">
            <div v-if="parseInt(competitor.status) !== 1" :class="$style.rightStatus">
                {{ status.text }}
            </div>
            <div v-else-if="competitor.time !== null" :class="$style.results">
                <!-- Faults -->
                <div
                    v-if="competitor.faults !== null && !isNaN(competitor.faults) && !stats"
                    :class="[$style.resultsGroup, $style.resultFault]"
                >
                    <div :class="$style.resultsTitle"> {{ $tc('faults', 2) }} </div>
                    <div v-if="previousFaults !== false" :class="$style.resultsContent">
                        <!-- totalFaults is the sum of faults from 1 and 2 manches -->
                        {{ previousFaults }}/{{ competitor.totalFaults - previousFaults }}
                        <span :class="$style.jumpFaults"> {{ jumpFaults }} </span>
                    </div>
                    <div v-else :class="$style.resultsContent">
                        {{ competitor.totalFaults }} <span :class="$style.jumpFaults"> {{ jumpFaults }} </span>
                    </div>
                </div>

                <!-- Time -->
                <div
                    v-if="!isNaN(competitor.time) && competitor.pastTime && competitor.pastTime !== false"
                    :class="[$style.resultsGroup, $style.resultTimeLarge]"
                >
                    <div :class="$style.resultsTitle"> {{ $t('times') }} </div>
                    <div :class="$style.resultsContent">
                        {{ competitor.pastTime }}/{{ competitor.time.toFixed(2) }}
                    </div>
                </div>
                <div v-else-if="!isNaN(competitor.time)" :class="[$style.resultsGroup, $style.resultTime]">
                    <div :class="$style.resultsTitle"> {{ $t('times') }} </div>
                    <div :class="$style.resultsContent">
                        {{ competitor.time.toFixed(2) }}
                    </div>
                </div>
                <!-- Time diff -->
                <div v-if="stats" :class="$style.diffWrapper">
                    <div :class="$style.timeDiff">
                        {{ timeDiff }}
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<style lang="scss" module>
@import '@design';

.wrapper {
    @include unselectable;

    display: flex;
    align-items: center;
    padding: 4px;
    margin: 0 -4px;
    border-radius: $border-radius;
    &:not(:last-child) {
        margin-bottom: 5px;
    }
}
.wrapperSelected {
    background: var(--color-background-hover);
}
.odd {
    background: var(--color-background-tertiary);
}
// Photo
.photo {
    position: relative;
    margin-left: 5px;
}
.photoImage {
    margin-right: 10px;
    margin-left: 5px;
}
.photoRank {
    position: absolute;
    bottom: 0;
    left: 5px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 15px;
    height: 15px;
    font-size: 9px;
    font-weight: 600;
    color: black;
    background: white;
    border-radius: $border-radius-round;
    box-shadow: 0 0 3px 0 black;
}

.nameHorse {
    margin-top: 3px;
    font-size: 0.85em;
    font-weight: 400;
    opacity: 0.7;
}
.country {
    position: absolute;
    bottom: 0;
    right: 10px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
}

.qualified {
    border: solid 2px var(--color-text-dark);
    height: 100%;
    position: absolute;
    margin-left: -5px;
}

// Right
.right {
    display: flex;
    flex: 1;
    justify-content: flex-end;
}
.rightStatus {
    display: inline-flex;
    padding: 5px;
    font-size: 12px;
    color: var(--color-text-dark);
    border: 1px solid var(--color-text-dark);
    border-radius: $border-radius;
    white-space: nowrap;
}
.results {
    display: flex;
    height: 100%;
}
.resultsGroup {
    display: flex;
    flex: 1;
    flex-direction: column;
    padding: 0 10px;
}
.resultsTitle {
    margin-bottom: 3px;
    font-size: 0.6em;
    font-weight: 600;
    text-transform: uppercase;
    opacity: 0.8;
}
.resultsContent {
    font-size: 1.2em;
    font-weight: 800;
}
.resultTime {
    width: 70px;
}
.resultTimeLarge {
    width: 120px;
}
.resultFault {
    width: 105px;
}
.jumpFaults {
    font-size: 0.5em;
    font-weight: 400;
    color: var(--color-text-dark);
}
// Time diff
.diffWrapper {
    margin-top: 10px;
    width: 50px;
}
.timeDiff {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 5px;
    border-radius: 5px;
    // text
    font-family: var(--font-family)-heading;
    font-size: 0.7em;
    font-weight: 800;
    color: var(--color-secondary);
    &:not(:first-child) {
        margin-left: 3px;
    }
    color: var(--color-background-darkest);
    background-color: var(--color-red);
}
</style>
