<script>
export default {
    props: {
        to: {
            type: Object,
            default: () => {},
        },
        color: {
            type: String,
            default: '',
        },
        size: {
            type: String,
            default: 'small',
        },
    },
    computed: {
        colorClass() {
            return this.color ? this.$style[`${this.color}Color`] : ''
        },
        sizeClass() {
            return this.size ? this.$style[`${this.size}Size`] : ''
        },
    },
}
</script>

<template>
    <component :is="to ? 'BaseLink' : 'div'" :to="to" :class="[$style.button, colorClass, sizeClass]">
        <slot />
    </component>
</template>

<style lang="scss" module>
@import '@design';

.button {
    cursor: pointer;
    white-space: nowrap;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    height: 40px;
    padding: 0 14px;
    box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08);
    background: #fff;
    border-radius: 5px;
    border: 1px solid;
    font-weight: 800;
    letter-spacing: 0.025em;
    color: var(--color-background-secondary);
    text-decoration: none;
    transition: all 0.15s ease;
}
.button + .button {
    margin-left: 5px;
}
.primaryColor {
    color: white;
    background: var(--color);
}
.outlinedColor {
    color: white;
    background: transparent;
    border-color: var(--color-border);
}

// Sizes
.smallSize {
    height: 30px;
    font-size: 0.85em;
}
</style>
