import store from '@state/store'

export default [
    {
        path: '/',
        name: 'index',
        redirect: { name: 'sessions' },
    },
    {
        path: '/sessions',
        name: 'sessions',
        component: require('@views/sessions').default,
        redirect: { name: 'sessions.today' },
        meta: {
            beforeResolve(routeTo, routeFrom, next) {
                store.dispatch('session/FETCH_SESSIONS').then(() => next())
            },
        },
        children: [
            {
                path: 'today',
                name: 'sessions.today',
                component: require('@views/sessions').default,
                meta: {
                    filter: 'today',
                },
            },
            {
                path: 'all',
                name: 'sessions.all',
                meta: {
                    filter: 'all',
                },
            },
            {
                path: 'coming-soon',
                name: 'sessions.coming-soon',
                meta: {
                    filter: 'comingSoon',
                },
            },
            {
                path: 'past',
                name: 'sessions.past',
                meta: {
                    filter: 'past',
                },
            },
        ],
    },
    // for redirections in testing period
    {
        path: '/home',
        name: 'coming-soon',
        component: require('@views/coming-soon').default,
    },
    {
        path: '/:id',
        redirect: { name: 'session.map' },
        component: require('@views/session').default,
        meta: {
            beforeResolve(routeTo, routeFrom, next) {
                store.dispatch('session/FETCH_SESSION', routeTo.params.id).then((session) => {
                    if (!session) {
                        window.location.href = '/'
                    }
                    next()
                })
            },
        },
        children: [
            {
                path: 'map',
                name: 'session.map',
                component: require('@views/session-map').default,
            },
            {
                path: 'competitors',
                name: 'session.competitors',
                component: require('@views/session-competitors').default,
            },
            {
                path: 'ghost-riders',
                name: 'session.ghost-riders',
                component: require('@views/session-ghost-riders').default,
            },
            {
                path: 'results',
                name: 'session.results',
                component: require('@views/session-results').default,
            },
            {
                path: 'statistics',
                name: 'session.statistics',
                redirect: { name: 'session.statistics.knocked-jumps' },
                component: require('@views/session-statistics').default,
                children: [
                    {
                        path: 'jumps',
                        name: 'session.statistics.knocked-jumps',
                        component: require('@views/statistics/knocked-jumps').default,
                    },
                    {
                        path: 'riders',
                        name: 'session.statistics.riders-out-of-time',
                        component: require('@views/statistics/riders-out-of-time').default,
                    },
                ],
            },
        ],
    },
]
