<script>
export default {
    props: {
        position: {
            type: String,
            default: 'center',
        },
        size: {
            type: String,
            default: 'large',
        },
    },
}
</script>

<template>
    <p
        :class="[
            $style.intro,
            position === 'center' ? $style.positionCenter : '',
            size === 'large' ? $style.sizeLarge : '',
        ]"
    >
        <slot />
    </p>
</template>

<style lang="scss" module>
@import '@design';

.intro {
    font-size: 24px;
    font-weight: 400;
    line-height: 1.33;
    letter-spacing: 0.009em;

    @include mobile {
        font-size: 19px;
        line-height: 1.42;
        text-align: left;
        letter-spacing: 0.012em;
    }
}
.positionCenter {
    margin-right: auto;
    margin-left: auto;
}
.sizeLarge {
    width: 75%;

    @include mobile {
        width: 100%;
    }
}
</style>
