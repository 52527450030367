<script>
export default {
    props: {
        asset: {
            type: Object,
            required: true,
        },
    },
    methods: {
        setStyle(jump) {
            requestAnimationFrame(() => {
                this.$el.style.transform = `translate(-50%, -50%) scale(1.3)`
            })
        },
    },
}
</script>

<template>
    <g :style="setStyle(asset)" :class="[$style.asset]">
        <path
            d="M4.54,17.39,1.68,19.15,1.05,18,3.67,16.3c0-1.65,2-3.09,2.05-4.68a5,5,0,0,0-.41-1.87,1.31,1.31,0,0,1-.08-.85A12.5,12.5,0,0,1,8.12,4.84,3.63,3.63,0,0,1,10,3.72a4.21,4.21,0,0,1,2.91.59,5.36,5.36,0,0,1,1.25,1.22,5.82,5.82,0,0,1,1.29,2.68,5.89,5.89,0,0,1-.79,3.42,9.4,9.4,0,0,1-1.51,2.1,24.82,24.82,0,0,1-2.2,2A16.65,16.65,0,0,1,9.11,17a9.76,9.76,0,0,1-1.95.9A2,2,0,0,1,4.54,17.39Z"
            transform="translate(-1.05 -1.08)"
            fill="#92cddc"
            fill-rule="evenodd"
        /><polygon points="6.42 0 0.34 5.89 4.41 9.21 4.79 6.65 8.54 3.15 6.42 0" fill="silver" fill-rule="evenodd" />
    </g>
</template>

<style lang="scss" module>
@import '@design';

.asset {
    transform: translate(-50%, -50%);
    transform-box: fill-box;
    transform-origin: center;
}
.selected * {
    fill: var(--color-red);
    stroke: transparent;
}
</style>
