<script>
import design from '@design'
import { sleep } from '@utils/time'

export default {
    props: {
        path: {
            type: Array,
            required: true,
        },
        details: {
            type: Boolean,
            default: true,
        },
        fill: {
            type: Boolean,
            default: false,
        },
        color: {
            type: String,
            default: design['globalColorLink'],
        },
    },
    data() {
        return {
            show: false,
            maxHeight: 0,
            maxWidth: 0,
        }
    },
    computed: {
        fillGradient() {
            return this.fill ? 'url(#gradient)' : 'none'
        },
        jumpsHeigh() {
            return this.path.map((position) => position[1])
        },
        maxHeightDot() {
            return this.path.filter((position) => position[1] === Math.max.apply(Math, this.jumpsHeigh))[0]
        },
        viewBox() {
            return `0 0 ${this.maxWidth} ${this.maxHeight}`
        },
        svgPath() {
            return this.path.reduce(
                (acc, path, i, paths) =>
                    i === 0 ? `M ${path[0]},${path[1]}` : `${acc} ${this.bezierCommand(path, i, paths, true)}`,
                ''
            )
        },
    },
    async mounted() {
        this.getPathSizes(this.$refs.svg)
        this.setDashoffset(this.$refs.path)
        await sleep(100)
        this.show = true
        this.$refs.path.style['stroke-dashoffset'] = 0
    },
    methods: {
        getPathSizes(element) {
            const seizes = element.getBBox()
            this.maxHeight = seizes.height
            this.maxWidth = seizes.width
        },
        bezierCommand(path, i, paths, mainPath) {
            const cps = this.controlPath(paths[i - 1], paths[i - 2], path)
            const cpe = this.controlPath(path, paths[i - 1], paths[i + 1], true)
            const close = i === paths.length - 1 ? ' ' : ''
            if (path.status !== null || mainPath) {
                return `C ${cps[0]},${cps[1]} ${cpe[0]},${cpe[1]} ${path[0]},${path[1]}${close}`
            } else {
                return `${close}`
            }
        },
        controlPath(current, previous, next, reverse) {
            const pr = previous || current
            const ne = next || current
            const p = pr
            const n = ne
            const smoothing = 0.2
            const o = this.line(p, n)
            const angle = o.angle + (reverse ? Math.PI : 0)
            const length = o.length * smoothing
            const x = current[0] + Math.cos(angle) * length
            const y = current[1] + Math.sin(angle) * length
            return [x, y]
        },
        line(pointA, pointB) {
            const lengthX = pointB[0] - pointA[0]
            const lengthY = pointB[1] - pointA[1]
            return {
                length: Math.sqrt(Math.pow(lengthX, 2) + Math.pow(lengthY, 2)),
                angle: Math.atan2(lengthY, lengthX),
            }
        },
        setDashoffset(element) {
            const pathLength = element.getTotalLength()
            element.style['stroke-dasharray'] = pathLength + ' ' + pathLength
            element.style['stroke-dashoffset'] = pathLength
            return pathLength
        },
        dist(x1, x2, y1, y2) {
            return Math.sqrt((x2 -= x1) * x2 + (y2 -= y1) * y2)
        },
    },
}
</script>

<template>
    <svg
        v-if="path"
        ref="svg"
        :class="[$style.svg, fill ? $style.fill : '']"
        :viewBox="viewBox"
        preserveAspectRatio="xMidYMid meet"
    >
        <defs>
            <linearGradient id="gradient" x1="0%" y1="0%" x2="0%" y2="100%">
                <stop offset="0%" stop-color="#ffffff" stop-opacity="0" />
                <stop offset="70%" :stop-color="color" stop-opacity="0.2" />
            </linearGradient>
        </defs>
        <path
            id="path"
            ref="path"
            :d="svgPath"
            :class="[$style.path, show ? $style.pathShow : '']"
            :stroke="color"
            :fill="fillGradient"
        />
        <g v-if="details">
            <g :class="$style.pitch">
                <line :x1="maxHeightDot[0]" :y1="0" :x2="maxHeightDot[0]" :y2="maxHeightDot[1]" />
                <circle :cx="maxHeightDot[0]" cy="0" r="1" />
            </g>
            <circle :cx="maxHeightDot[0]" :cy="maxHeightDot[1]" :class="$style.maxHeight" r="2.5" />
            <text :x="maxHeightDot[0]" :y="maxHeightDot[1]" :class="$style.maxHeightName">
                {{ maxHeightDot[0] / 100 }}m
            </text>
        </g>
    </svg>
</template>

<style lang="scss" module>
@import '@design';

.path {
    fill-opacity: 0;
    stroke-width: 1;
    &.pathShow {
        fill-opacity: 1;
        transition: all 1s ease-out;
        animation: create;
    }
}

@keyframes create {
    to {
        stroke-dashoffset: 0;
    }
}
.svg {
    // width: 100%;
    // height: 100%;
    .fill {
        background: white;
    }
}
.maxHeight {
    fill: var(--color-link);
    stroke: white;
    stroke-width: 0.5px;
    transition: r 200ms;
    animation: pulsation 2.5s infinite ease-in-out;
    &:hover {
        animation: pulsation 2.5s infinite ease-in-out;
    }
}

@keyframes pulsation {
    50% {
        r: 3;
        stroke-width: 0.75px;
    }
}
.maxHeightName {
    font-size: 6px;
    text-anchor: middle;
    transform: translateY(10px);
}

.pitch {
    circle {
        fill: var(--color-text);
    }
    line {
        stroke: var(--color-text-lighter);
        stroke-dasharray: 1;
        stroke-width: 0.5;
    }
}
</style>
