const countryISOmapping = {
    AFG: 'AF',
    ALA: 'AX',
    ALB: 'AL',
    DZA: 'DZ',
    ASM: 'AS',
    AND: 'AD',
    AGO: 'AO',
    AIA: 'AI',
    ATA: 'AQ',
    ATG: 'AG',
    ARG: 'AR',
    ARM: 'AM',
    ABW: 'AW',
    AUS: 'AU',
    AUT: 'AT',
    AZE: 'AZ',
    BHS: 'BS',
    BHR: 'BH',
    BGD: 'BD',
    BRB: 'BB',
    BLR: 'BY',
    BEL: 'BE',
    BLZ: 'BZ',
    BEN: 'BJ',
    BMU: 'BM',
    BTN: 'BT',
    BOL: 'BO',
    BIH: 'BA',
    BWA: 'BW',
    BVT: 'BV',
    BRA: 'BR',
    VGB: 'VG',
    IOT: 'IO',
    BRN: 'BN',
    BGR: 'BG',
    BFA: 'BF',
    BDI: 'BI',
    KHM: 'KH',
    CMR: 'CM',
    CAN: 'CA',
    CPV: 'CV',
    CYM: 'KY',
    CAF: 'CF',
    TCD: 'TD',
    CHL: 'CL',
    CHN: 'CN',
    HKG: 'HK',
    MAC: 'MO',
    CXR: 'CX',
    CCK: 'CC',
    COL: 'CO',
    COM: 'KM',
    COG: 'CG',
    COD: 'CD',
    COK: 'CK',
    CRI: 'CR',
    CIV: 'CI',
    HRV: 'HR',
    CUB: 'CU',
    CYP: 'CY',
    DNK: 'DK',
    DJI: 'DJ',
    DMA: 'DM',
    DOM: 'DO',
    ECU: 'EC',
    EGY: 'EG',
    SLV: 'SV',
    GNQ: 'GQ',
    ERI: 'ER',
    EST: 'EE',
    ETH: 'ET',
    FLK: 'FK',
    FRO: 'FO',
    FJI: 'FJ',
    FIN: 'FI',
    FRA: 'FR',
    GUF: 'GF',
    PYF: 'PF',
    ATF: 'TF',
    GAB: 'GA',
    GMB: 'GM',
    GEO: 'GE',
    DEU: 'DE',
    GHA: 'GH',
    GIB: 'GI',
    GRC: 'GR',
    GRL: 'GL',
    GRD: 'GD',
    GLP: 'GP',
    GUM: 'GU',
    GTM: 'GT',
    GGY: 'GG',
    GIN: 'GN',
    GNB: 'GW',
    GUY: 'GY',
    HTI: 'HT',
    HMD: 'HM',
    VAT: 'VA',
    HND: 'HN',
    HUN: 'HU',
    ISL: 'IS',
    IND: 'IN',
    IDN: 'ID',
    IRN: 'IR',
    IRQ: 'IQ',
    IRL: 'IE',
    IMN: 'IM',
    ISR: 'IL',
    ITA: 'IT',
    JAM: 'JM',
    JPN: 'JP',
    JEY: 'JE',
    JOR: 'JO',
    KAZ: 'KZ',
    KEN: 'KE',
    KIR: 'KI',
    PRK: 'KP',
    KOR: 'KR',
    KWT: 'KW',
    KGZ: 'KG',
    LAO: 'LA',
    LVA: 'LV',
    LBN: 'LB',
    LSO: 'LS',
    LBR: 'LR',
    LBY: 'LY',
    LIE: 'LI',
    LTU: 'LT',
    LUX: 'LU',
    MKD: 'MK',
    MDG: 'MG',
    MWI: 'MW',
    MYS: 'MY',
    MDV: 'MV',
    MLI: 'ML',
    MLT: 'MT',
    MHL: 'MH',
    MTQ: 'MQ',
    MRT: 'MR',
    MUS: 'MU',
    MYT: 'YT',
    MEX: 'MX',
    FSM: 'FM',
    MDA: 'MD',
    MCO: 'MC',
    MNG: 'MN',
    MNE: 'ME',
    MSR: 'MS',
    MAR: 'MA',
    MOZ: 'MZ',
    MMR: 'MM',
    NAM: 'NA',
    NRU: 'NR',
    NPL: 'NP',
    NED: 'NL',
    NLD: 'NL',
    ANT: 'AN',
    NCL: 'NC',
    NZL: 'NZ',
    NIC: 'NI',
    NER: 'NE',
    NGA: 'NG',
    NIU: 'NU',
    NFK: 'NF',
    MNP: 'MP',
    NOR: 'NO',
    OMN: 'OM',
    PAK: 'PK',
    PLW: 'PW',
    PSE: 'PS',
    PAN: 'PA',
    PNG: 'PG',
    PRY: 'PY',
    PER: 'PE',
    PHL: 'PH',
    PCN: 'PN',
    POL: 'PL',
    PT: 'PT',
    PRT: 'PT',
    PRI: 'PR',
    QAT: 'QA',
    REU: 'RE',
    ROU: 'RO',
    RUS: 'RU',
    RWA: 'RW',
    BLM: 'BL',
    SHN: 'SH',
    KNA: 'KN',
    LCA: 'LC',
    MAF: 'MF',
    SPM: 'PM',
    VCT: 'VC',
    WSM: 'WS',
    SMR: 'SM',
    STP: 'ST',
    SAU: 'SA',
    SEN: 'SN',
    SRB: 'RS',
    SYC: 'SC',
    SLE: 'SL',
    SGP: 'SG',
    SVK: 'SK',
    SVN: 'SI',
    SLB: 'SB',
    SOM: 'SO',
    ZAF: 'ZA',
    SGS: 'GS',
    SSD: 'SS',
    ESP: 'ES',
    LKA: 'LK',
    SDN: 'SD',
    SUR: 'SR',
    SJM: 'SJ',
    SWZ: 'SZ',
    SWE: 'SE',
    CHE: 'CH',
    SYR: 'SY',
    TWN: 'TW',
    TJK: 'TJ',
    TZA: 'TZ',
    THA: 'TH',
    TLS: 'TL',
    TGO: 'TG',
    TKL: 'TK',
    TON: 'TO',
    TTO: 'TT',
    TUN: 'TN',
    TUR: 'TR',
    TKM: 'TM',
    TCA: 'TC',
    TUV: 'TV',
    UGA: 'UG',
    UKR: 'UA',
    ARE: 'AE',
    GBR: 'GB',
    USA: 'US',
    UMI: 'UM',
    URY: 'UY',
    UZB: 'UZ',
    VUT: 'VU',
    VEN: 'VE',
    VNM: 'VN',
    VIR: 'VI',
    WLF: 'WF',
    ESH: 'EH',
    YEM: 'YE',
    ZMB: 'ZM',
    ZWE: 'ZW',
    XKX: 'XK',
}

const countryCIOmapping = {
    AFG: 'AFG',
    ALB: 'ALB',
    ALG: 'DZA',
    AND: 'AND',
    ANG: 'AGO',
    ANT: 'ATG',
    ARG: 'ARG',
    ARM: 'ARM',
    ARU: 'ABW',
    ASA: 'ASM',
    AUS: 'AUS',
    AUT: 'AUT',
    AZE: 'AZE',
    BAH: 'BHS',
    BAN: 'BGD',
    BAR: 'BRB',
    BDI: 'BDI',
    BEL: 'BEL',
    BEN: 'BEN',
    BER: 'BMU',
    BHU: 'BTN',
    BIH: 'BIH',
    BIZ: 'BLZ',
    BLR: 'BLR',
    BOL: 'BOL',
    BOT: 'BWA',
    BRA: 'BRA',
    BRN: 'BHR',
    BRU: 'BRN',
    BUL: 'BGR',
    BUR: 'BFA',
    CAF: 'CAF',
    CAM: 'KHM',
    CAN: 'CAN',
    CAY: 'CYM',
    CGO: 'COG',
    CHA: 'TCD',
    CHI: 'CHL',
    CHN: 'CHN',
    CIV: 'CIV',
    CMR: 'CMR',
    COD: 'COD',
    COK: 'COK',
    COL: 'COL',
    COM: 'COM',
    COR: 'COR',
    CPV: 'CPV',
    CRC: 'CRI',
    CRO: 'HRV',
    CUB: 'CUB',
    CYP: 'CYP',
    CZE: 'CZE',
    DEN: 'DNK',
    DJI: 'DJI',
    DMA: 'DMA',
    DOM: 'DOM',
    ECU: 'ECU',
    EGY: 'EGY',
    ERI: 'ERI',
    ESA: 'SLV',
    ESP: 'ESP',
    EST: 'EST',
    ETH: 'ETH',
    FIJ: 'FJI',
    FIN: 'FIN',
    FRA: 'FRA',
    FSM: 'FSM',
    GAB: 'GAB',
    GAM: 'GMB',
    GBR: 'GBR',
    GBS: 'GNB',
    GEO: 'GEO',
    GEQ: 'GNQ',
    GER: 'DEU',
    GHA: 'GHA',
    GRE: 'GRC',
    GRN: 'GRD',
    GUA: 'GTM',
    GUI: 'GIN',
    GUM: 'GUM',
    GUY: 'GUY',
    HAI: 'HTI',
    HKG: 'HKG',
    HON: 'HND',
    HUN: 'HUN',
    INA: 'IDN',
    IND: 'IND',
    IRI: 'IRN',
    IRL: 'IRL',
    IRQ: 'IRQ',
    ISL: 'ISL',
    ISR: 'ISR',
    ISV: 'VIR',
    ITA: 'ITA',
    IVB: 'VGB',
    JAM: 'JAM',
    JOR: 'JOR',
    JPN: 'JPN',
    KAZ: 'KAZ',
    KEN: 'KEN',
    KGZ: 'KGZ',
    KIR: 'KIR',
    KOR: 'KOR',
    KSA: 'SAU',
    KUW: 'KWT',
    LAO: 'LAO',
    LAT: 'LVA',
    LBA: 'LBY',
    LBN: 'LBN',
    LBR: 'LBR',
    LCA: 'LCA',
    LES: 'LSO',
    LIE: 'LIE',
    LTU: 'LTU',
    LUX: 'LUX',
    MAD: 'MDG',
    MAR: 'MAR',
    MAS: 'MYS',
    MAW: 'MWI',
    MDA: 'MDA',
    MDV: 'MDV',
    MEX: 'MEX',
    MGL: 'MNG',
    MHL: 'MHL',
    MKD: 'MKD',
    MLI: 'MLI',
    MLT: 'MLT',
    MNE: 'MNE',
    MON: 'MCO',
    MOZ: 'MOZ',
    MRI: 'MUS',
    MTN: 'MRT',
    MYA: 'MMR',
    NAM: 'NAM',
    NCA: 'NIC',
    NED: 'NLD',
    NEP: 'NPL',
    NGR: 'NGA',
    NIG: 'NER',
    NOR: 'NOR',
    NRU: 'NRU',
    NZL: 'NZL',
    OMA: 'OMN',
    PAK: 'PAK',
    PAN: 'PAN',
    PAR: 'PRY',
    PER: 'PER',
    PHI: 'PHL',
    PLE: 'PSE',
    PLW: 'PLW',
    PNG: 'PNG',
    POL: 'POL',
    POR: 'PRT',
    PRK: 'PRK',
    PUR: 'PRI',
    QAT: 'QAT',
    ROU: 'ROU',
    RSA: 'ZAF',
    RUS: 'RUS',
    RWA: 'RWA',
    SAM: 'WSM',
    SEN: 'SEN',
    SEY: 'SYC',
    SGP: 'SGP',
    SKN: 'KNA',
    SLE: 'SLE',
    SLO: 'SVN',
    SMR: 'SMR',
    SOL: 'SLB',
    SOM: 'SOM',
    SRB: 'SRB',
    SRI: 'LKA',
    SSD: 'SSD',
    STP: 'STP',
    SUD: 'SDN',
    SUI: 'CHE',
    SUR: 'SUR',
    SVK: 'SVK',
    SWE: 'SWE',
    SWZ: 'SWZ',
    SYR: 'SYR',
    TAN: 'TZA',
    TGA: 'TON',
    THA: 'THA',
    TJK: 'TJK',
    TKM: 'TKM',
    TLS: 'TLS',
    TOG: 'TGO',
    TPE: 'TWN',
    TTO: 'TTO',
    TUN: 'TUN',
    TUR: 'TUR',
    TUV: 'TUV',
    UAE: 'ARE',
    UGA: 'UGA',
    UKR: 'UKR',
    URU: 'URY',
    USA: 'USA',
    UZB: 'UZB',
    VAN: 'VUT',
    VEN: 'VEN',
    VIE: 'VNM',
    VIN: 'VCT',
    YEM: 'YEM',
    ZAM: 'ZMB',
    ZIM: 'ZWE',
}

export default (countryCode) => {
    return countryCode.length === 2
        ? countryCode
        : countryISOmapping[countryCIOmapping[countryCode]]
        ? countryISOmapping[countryCIOmapping[countryCode]]
        : ''
}
