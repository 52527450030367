<script>
import Layout from '@layouts/main'
// Store
import { mapState } from 'vuex'
// Components
import ListContent from '@components/list-content'
import ListTitles from '@components/list-titles'
import ListImage from '@components/list-image'
import ListStatus from '@components/list-status'
// Utils
import design from '@design'
import { dateTimeToDateAndTime } from '@utils/time'
// Toasts
import SessionIsLive from '@components/toasts/SessionIsLive'
export default {
    components: {
        Layout,
        ListContent,
        ListTitles,
        ListImage,
        ListStatus,
        SessionIsLive,
    },
    data() {
        return {
            badgeColors: {
                main: design['colorTextDark'],
                text: design['colorSecondary'],
            },
            displayTabs: {
                all: false,
                today: false,
                past: false,
                comingSoon: false,
            },
        }
    },
    mounted() {
        // WEBSOCKETS
        window.Echo.channel('live').listen('LiveSessionBroadcastResync', () => {
            window.location.reload()
        })
        window.Echo.channel('live').listen('LiveSessionBroadcast', ({ datas }) => {
            if (datas.hasOwnProperty('type') && datas.type === 'toast') {
                if (datas.component === 'SessionIsLive') {
                    this.$toast.dismiss('SessionIsLive')
                    this.$toast(
                        {
                            component: SessionIsLive,
                            props: {
                                url: datas.url,
                                translator: function(key) {
                                    return this.$t(key)
                                }.bind(this),
                            },
                            listeners: {},
                        },
                        {
                            id: 'SessionIsLive',
                            timeout: 300000,
                            icon: false,
                            closeOnClick: false,
                            ...datas.options,
                        }
                    )
                }
            }
        })
    },
    computed: {
        ...mapState('session', {
            sessions: (state) => state.sessions,
        }),
        ordoredSessions() {
            let sessionsResult = {
                all: {},
                today: {},
                comingSoon: {},
                past: {},
            }
            if (!this.sessions) return sessionsResult
            for (let index = this.sessions.length - 1; index >= 0; index--) {
                const session = this.sessions[index]
                const now = new Date()

                const year = session.begin_at.split('-')[0]

                // Today
                if (new Date(session.begin_at).setHours(0, 0, 0, 0) === now.setHours(0, 0, 0, 0)) {
                    if (year in sessionsResult.today === false) sessionsResult.today[year] = []

                    sessionsResult.today[year].push(session)
                    this.displayTabs.today = true
                }
                // Coming soon
                else if (new Date(session.begin_at) > now) {
                    if (year in sessionsResult.comingSoon === false) sessionsResult.comingSoon[year] = []

                    sessionsResult.comingSoon[year].push(session)
                    this.displayTabs.comingSoon = true
                }
                // Past
                else {
                    if (year in sessionsResult.past === false) sessionsResult.past[year] = []

                    sessionsResult.past[year].push(session)
                    this.displayTabs.past = true
                }

                // All
                if (year in sessionsResult.all === false) sessionsResult.all[year] = []

                sessionsResult.all[year].push(session)
                this.displayTabs.all = true
            }

            return sessionsResult
        },
    },
    methods: {
        getDateAndTime(date) {
            return dateTimeToDateAndTime(date)
        },
        getRedirectionURL(session) {
            return process.env[`VUE_APP_${session.theme.toUpperCase()}_URL`] + session.id
        },
    },
    watch: {
        ordoredSessions: {
            handler: function(newValue) {
                if (this.$route.meta.filter === 'today' && !this.displayTabs.today) {
                    if (this.displayTabs.comingSoon) this.$route.meta.filter = 'comingSoon'
                    else this.$route.meta.filter = 'all'
                }
            },
            immediate: true,
        },
    },
}
</script>

<template>
    <Layout>
        <BaseHeader />

        <div :class="$style.menu">
            <BaseMenu v-if="displayTabs.comingSoon || displayTabs.today">
                <BaseMenuLink
                    v-if="displayTabs.today"
                    to="sessions.today"
                    secondary
                    :active="this.$route.meta.filter === 'today'"
                >
                    {{ $t('today') }}
                </BaseMenuLink>
                <BaseMenuLink
                    v-if="displayTabs.comingSoon"
                    to="sessions.coming-soon"
                    secondary
                    :active="this.$route.meta.filter === 'comingSoon'"
                >
                    {{ $t('coming-soon') }}
                </BaseMenuLink>
                <BaseMenuLink
                    v-if="displayTabs.past"
                    to="sessions.past"
                    secondary
                    :active="this.$route.meta.filter === 'past'"
                >
                    {{ $t('past') }}
                </BaseMenuLink>
                <BaseMenuLink
                    v-if="displayTabs.all"
                    to="sessions.all"
                    secondary
                    :active="this.$route.meta.filter === 'all'"
                >
                    {{ $t('all') }}
                </BaseMenuLink>
            </BaseMenu>
        </div>

        <main :class="$style.main">
            <BaseContainer>
                <template v-if="Object.keys(ordoredSessions[$route.meta.filter]).length">
                    <BaseTable
                        v-for="(year, indexYear) in Object.keys(ordoredSessions[$route.meta.filter]).reverse()"
                        :key="year"
                        :title="year"
                        collapsable
                        :collapsed="indexYear !== 0"
                        :class="$style.table"
                    >
                        <!-- Body -->
                        <BaseTableBody :data="ordoredSessions[$route.meta.filter][year]">
                            <template #default="{ item: session }">
                                <BaseLink
                                    :to="
                                        $theme !== session.theme
                                            ? null
                                            : {
                                                  name: 'session.map',
                                                  params: { id: session.id },
                                              }
                                    "
                                    :href="$theme !== session.theme ? getRedirectionURL(session) : null"
                                    tag="th"
                                >
                                    <ListContent>
                                        <ListImage :image="session.competition.concour.logo" :isLogo="true" />
                                        <ListTitles>
                                            {{ session.name }}
                                            <template #subtext>
                                                <div :class="$style.subtext">
                                                    <span>
                                                        {{
                                                            `${getDateAndTime(session.begin_at).date} ${$t('at')} 
                                                        ${getDateAndTime(session.begin_at).time}`
                                                        }}
                                                    </span>
                                                    <BaseBadge
                                                        v-if="session.is_live"
                                                        text="Live"
                                                        :color="badgeColors.main"
                                                        :text-color="badgeColors.text"
                                                        :pulse="true"
                                                    ></BaseBadge>
                                                </div>
                                            </template>
                                        </ListTitles>
                                    </ListContent>
                                </BaseLink>
                            </template>
                        </BaseTableBody>
                    </BaseTable>
                </template>
                <BaseEmpty v-else>
                    <div> {{ $t('no-session') }} </div>
                    <BaseButton :to="{ name: 'sessions' }" :class="$style.messageButton">
                        {{ $t('back-to-classes') }}
                    </BaseButton>
                </BaseEmpty>
            </BaseContainer>
        </main>
        <BaseFooter />
    </Layout>
</template>

<style lang="scss" module>
@import '@design';

.main {
    display: flex;
    flex: 1;
    padding-bottom: 30px;
}

.menu {
    margin-top: 7px;
    margin-left: 15px;
}

.subtext {
    display: flex;
    align-items: center;
    margin-top: 10px;
    text-transform: lowercase;
}

.messageButton {
    margin-top: 10px;
}

.table:not(:last-child) {
    margin-bottom: 20px;
}
</style>
