<script>
import Vue from 'vue'
import FlashContainer from '@components/flash/flash-container'
import { sleep } from '@utils/time'

export default {
    components: {
        FlashContainer,
    },
    async mounted() {
        const theme = Vue.prototype.$theme
        console.log(window.location.hostname, theme)
        document.documentElement.setAttribute('theme', theme)
        const icon32 = document.getElementById('icon32')
        icon32.href = `favicon/${theme}/favicon-32x32.png`
        const icon16 = document.getElementById('icon16')
        icon16.href = `favicon/${theme}/favicon-16x16.png`
        const iconshortcut = document.getElementById('iconshortcut')
        iconshortcut.href = `favicon/${theme}/favicon.ico`
        const icon180 = document.getElementById('icon180')
        icon180.href = `favicon/${theme}/apple-touch-icon.png`

        if (!localStorage.suscribed) {
            await sleep(10000)
            this.$refs.marketing.show()
        }
    },
}
</script>

<template>
    <div :class="$style.app">
        <RouterView />
        <FlashContainer ref="marketing" name="marketing" :duration="100000000" />
    </div>
</template>

<style lang="scss">
// Allow element/type selectors, because this is global CSS.
/* stylelint-disable selector-max-type, selector-class-pattern */
@import '~normalize.css/normalize.css';
@import '~nprogress/nprogress.css';
@import '~croppie/croppie.css';
@import '@src/design/font-face.scss';
@import '@design';

blockquote,
button,
figure,
h1,
h2,
h3,
h4,
h5,
h6,
ol,
p,
pre,
ul {
    padding: 0;
    margin: 0;
}
::-webkit-scrollbar {
    width: 2px;
}
::-webkit-scrollbar-track {
    background: transparent;
}
::-webkit-scrollbar-thumb {
    background: #888;
}
:root {
    height: 100%;
    overflow-x: hidden;
}
* {
    box-sizing: border-box;
    -webkit-tap-highlight-color: transparent;
}
body {
    display: flex;
    flex-direction: column;
    min-width: 320px;
    min-height: 100%;
    font-family: var(--font-family);
    font-weight: var(--font-weight);
    font-size: 14px;
    line-height: 1;
    color: var(--color-text);
    text-align: left;
    background-color: var(--color-background);
}
html,
body {
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    -webkit-touch-callout: none;
    -webkit-text-size-adjust: 100%;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscales;
}
img,
svg {
    display: block;
}
ol,
ul {
    list-style: none;
}
button,
input,
optgroup,
select,
textarea {
    padding: 0;
    margin: 0;
    font-weight: inherit;
    line-height: 1;
    color: inherit;
    background: transparent;
    border: 0;
    outline: none;
    appearance: none;
}
a {
    text-decoration: none;
}
hr {
    box-sizing: content-box;
    height: 0;
    overflow: visible;
    border: 0;
    border-top: 1px solid rgba(white, 0.1);
}

// ===
// Vendor
// ===
#nprogress {
    .bar {
        background: var(--color-link);
    }
    .peg {
        box-shadow: 0 0 10px var(--color-link), 0 0 5px var(--color-link);
    }
}
/* stylelint-enable */
</style>

<style lang="scss" module>
.app {
    display: flex;
    flex: 1;
    flex-direction: column;
}
</style>
