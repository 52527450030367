<script>
export default {
    props: {
        isSelected: {
            type: Boolean,
            default: false,
        },
    },
}
</script>

<template>
    <circle r="1" :class="[$style.liverpool, isSelected ? $style.selected : '']" />
</template>

<style lang="scss" module>
@import '@design';

.liverpool {
    fill: var(--color-blue);
    stroke: var(--color-blue);
}
.selected {
    fill: var(--color-red);
    stroke: transparent;
}
</style>
