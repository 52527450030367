<template>
    <div>
        <BaseLink v-if="isIOSdevice" tag="div" :href="src">
            <div ref="player">
                <iframe :src="src" allowfullscreen></iframe>
            </div>
        </BaseLink>

        <div v-else ref="player">
            <iframe :src="src" allowfullscreen></iframe>
        </div>
    </div>
</template>

<script>
import Plyr from 'plyr'
import 'plyr/dist/plyr.css'
export default {
    name: 'BaseVideo',
    props: {
        src: {
            type: String,
            required: true,
        },
    },
    data() {
        return {
            player: {},
            isIOSdevice: /iPad|iPhone|iPod/i.test(navigator.userAgent) && !window.MSStream,
        }
    },
    mounted() {
        try {
            this.player = new Plyr(this.$refs.player, this.opts)
        } catch (e) {
            alert(e)
        }
    },
    beforeUnmount() {
        try {
            this.player.destroy()
        } catch (e) {
            console.error(e)
        }
    },
}
</script>
