<script>
export default {
    props: {
        href: {
            type: String,
            default: '',
        },
        allowInsecure: {
            type: Boolean,
            default: false,
        },
        to: {
            type: Object,
            default: null,
        },
        name: {
            type: String,
            default: '',
        },
        params: {
            type: Object,
            default: () => ({}),
        },
    },
    computed: {
        routerLinkTo({ name, params }) {
            return {
                name,
                params,
                ...(this.to || {}),
            }
        },
    },
    created() {
        this.validateProps()
    },
    methods: {
        // Perform more complex prop validations than is possible
        // inside individual validator functions for each prop.
        validateProps() {
            if (process.env.NODE_ENV === 'production') return

            if (!this.href) {
                // Check for insufficient props.
                if (!this.name && !this.to) {
                    return console.warn(
                        `Invalid <BaseLink> props:\n\n${JSON.stringify(
                            this.$props,
                            null,
                            2
                        )}\n\nEither a \`name\` or \`to\` is required for internal links, or an \`href\` for external links.`
                    )
                }
            }
        },
    },
}
</script>

<template>
    <a v-if="href" :href="href" target="_blank" :class="[$style.pointer, $attrs.tag === 'th' ? $style.th : '']">
        <component v-if="$attrs.tag" :is="$attrs.tag">
            <slot />
        </component>
        <slot v-else />
    </a>

    <RouterLink v-else v-bind="$attrs" :to="routerLinkTo" :class="$style.pointer">
        <slot />
    </RouterLink>
</template>

<style lang="scss" module>
.pointer {
    cursor: pointer;
}
.th {
    display: flex;
    color: inherit;

    th {
        width: 100%;
    }
}
</style>
