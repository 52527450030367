<script>
import { mapState, mapActions, mapMutations, mapGetters } from 'vuex'

export default {
    props: {
        title: {
            type: String,
            required: true,
        },
        icon: {
            type: String,
            default: '',
        },
        lists: {
            type: Array,
            required: true,
        },
    },
    computed: {
        ...mapGetters('session/editor', ['hasSelected']),
        ...mapState('session/editor', {
            selected: (state) => state.selected,
            lastElementClicked: (state) => state.lastElementClicked,
        }),
    },
    methods: {
        ...mapActions('session/editor', ['toggleSelected']),
        ...mapMutations('session/editor', ['SET_LAST_ELEMENT_CLICKED']),
        isSelected(currentId) {
            return this.selected.some((selectId) => selectId === currentId)
        },
        setSelected(newValue, event) {
            this.toggleSelected(newValue)
            this.SET_LAST_ELEMENT_CLICKED(event.currentTarget)
        },
    },
}
</script>

<template>
    <div :class="$style.lists">
        <!-- Title -->
        <div :class="$style.title">
            <span>
                <BaseIcon v-if="icon" :name="icon" />
                {{ title }}
            </span>
            <BaseIcon v-if="$listeners.add" :class="$style.addMore" name="add" @click.native="$emit('add')" />
        </div>
        <!-- List -->
        <ul :class="$style.list">
            <slot>
                <li
                    v-for="(list, key) in lists"
                    :key="icon + key"
                    :class="[isSelected(list.id) ? $style.isSelected : '', !list.visible ? $style.isHidden : '']"
                    @click="setSelected(list, $event)"
                >
                    <div :class="$style.listKey">
                        {{ key + 1 }}
                    </div>
                    <div :class="$style.listContent">
                        <span>
                            {{ list.name }}
                        </span>
                        <div :class="$style.actions">
                            <BaseIcon
                                v-show="!list.lock"
                                name="delete"
                                @click.native.prevent.stop="$emit('delete', list)"
                            />
                            <BaseIcon
                                v-show="!list.lock"
                                :name="list.visible ? 'visibility' : 'visibility-off'"
                                @click.native.prevent.stop="$emit('visibility', list)"
                            />
                            <BaseIcon
                                :name="list.lock ? 'lock' : 'lock-open'"
                                @click.native.prevent.stop="$emit('lock', list)"
                            />
                        </div>
                    </div>
                </li>
                <div v-if="!lists.length" :class="$style.empty">
                    <div :class="$style.emptyTitle">
                        <slot name="emptyTitle"> {{ $t('no-one') }} </slot>
                    </div>
                    <div :class="$style.emptyContent">
                        <slot name="emptyContent"> {{ $t('add-one') }} </slot>
                    </div>
                </div>
            </slot>
        </ul>
    </div>
</template>

<style lang="scss" module>
@import '@design';

.lists {
    @include unselectable;
    &:not(:last-child) {
        margin-bottom: 20px;
    }
}
.list {
    .listKey {
        width: 14px;
        height: 14px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 7px;
        font-size: 8px;
        font-weight: 600;
        color: var(--color-secondary);
        background: var(--color-secondary-lighter);
        border-radius: $border-radius-round;
    }
    .listContent {
        display: flex;
        flex: 1;
        align-items: center;
        justify-content: space-between;
    }
    li {
        @include font-heading;
        position: relative;
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: 30px;
        padding-left: 5px;
        margin-top: 1px;
        margin-left: 10px;
        color: var(--color-secondary);
        cursor: pointer;
        &:first-child::before {
            top: -4px;
            height: 35px;
        }
        &:last-child::before {
            height: 16px;
        }
        &::before,
        &::after {
            display: block;
            align-self: self-start;
            content: '';
            position: absolute;
            top: 0;
            bottom: 0;
            left: -10px;
            margin-right: 5px;
            margin-left: 8px;
            background: rgba(255, 255, 255, 0.15);
        }
        &::before {
            width: 1px;
            height: 31px;
        }
        &::after {
            width: 6px;
            height: 1px;
            top: 15px;
            left: -9px;
        }
        &:hover {
            color: var(--color-blue);
            background: var(--color-background-blue-hover);
            border-radius: $border-radius;
        }
        &:active {
            color: var(--color-secondary);
            background: var(--color-blue);
            &::after {
                border: 1px solid var(--color-secondary);
            }
        }
        &.isSelected {
            color: var(--color-red);
            background: var(--color-red-lighter);
            border-radius: $border-radius;
        }
    }
}
.actions {
    display: flex;
    margin-right: 3px;
    svg {
        width: 14px;
        height: 14px;
        margin-left: 3px;
        opacity: 1;
        fill: var(--color-secondary);
    }
}
.title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 25px;
    svg {
        width: 18px;
        height: 18px;
        fill: var(--color-secondary-light);
    }
    span {
        position: relative;
        z-index: 1;
        display: flex;
        align-items: center;
        font-size: 12px;
        svg {
            margin-right: 5px;
        }
    }
    &:not(:first-child) {
        margin-top: 10px;
    }
}
.empty {
    padding: 10px 0;
    margin: 0 30px;
    overflow: hidden;
    transition: height 0.4s cubic-bezier(0.8, 0, 0, 1);
    .emptyTitle {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        opacity: 0.5;
    }
    .emptyContent {
        margin-top: 5px;
        line-height: 21px;
        opacity: 0.3;
    }
}
.addMore {
    cursor: pointer;
    &:hover {
        background: var(--color-background-blue-hover);
        border-radius: $border-radius-round;
        fill: var(--color-blue);
    }
    &:active {
        background: var(--color-blue);
        fill: var(--color-secondary);
    }
}
.isHidden {
    opacity: 0.3;
}
</style>
