<script>
export default {
    inheritAttrs: false,
    model: {
        event: 'update',
    },
    props: {
        value: {
            validator: (prop) =>
                typeof prop === 'string' || typeof prop === 'number' || prop === null || typeof prop === 'object', // object for datetime-local
            default: '',
        },
        type: {
            type: String,
            default: 'text',
            validator(value) {
                return ['email', 'password', 'search', 'tel', 'text', 'url', 'datetime-local'].includes(value)
            },
        },
        size: {
            type: String,
            default: '',
            validator(value) {
                return ['', 'big'].includes(value)
            },
        },
        autocomplete: {
            type: String,
            default: null,
        },
        name: {
            type: String,
            default: null,
        },
        placeholder: {
            type: String,
            default: '',
        },
        details: {
            type: String,
            default: null,
        },
    },
    computed: {
        sizeClass() {
            return this.size ? this.$style[`${this.size}Size`] : ''
        },
    },
}
</script>

<template>
    <BaseFormField>
        <div v-if="details" :class="$style.details">
            {{ details }}
        </div>
        <input
            :autocomplete="autocomplete"
            :name="name"
            :placeholder="placeholder"
            :value="value"
            :type="type"
            :class="[$style.input, sizeClass]"
            v-bind="$attrs"
            v-on="$listeners"
            @input="$emit('update', $event.target.value)"
        />
    </BaseFormField>
</template>

<style lang="scss" module>
@import '@design';

.input {
    @include inputEdit;
}
.bigSize {
    height: 40px;
    font-size: 1.1em;
}
.details {
    display: inline;
    margin-top: 5px;
    font-size: 10px;
    cursor: default;
    opacity: 0.6;
    color: var(--color-error);
}
</style>
