<script>
export default {
    props: {
        paths: {
            type: Array,
            required: true,
        },
    },
    computed: {
        pathsFormatted() {
            return this.paths.map((path) => {
                return [path.x, path.y]
            })
        },
        draw() {
            return this.pathsFormatted.reduce(
                (acc, path, i, paths) =>
                    i === 0 ? `M ${path[0]},${path[1]}` : `${acc} ${this.bezierCommand(path, i, paths, true)}`,
                ''
            )
        },
    },
    methods: {
        bezierCommand(path, i, paths, mainPath) {
            const cps = this.controlPath(paths[i - 1], paths[i - 2], path)
            const cpe = this.controlPath(path, paths[i - 1], paths[i + 1], true)
            const close = i === paths.length - 1 ? ' ' : ''
            if (path.status !== null || mainPath) {
                return `C ${cps[0]},${cps[1]} ${cpe[0]},${cpe[1]} ${path[0]},${path[1]}${close}`
            } else {
                return `${close}`
            }
        },
        controlPath(current, previous, next, reverse) {
            const pr = previous || current
            const ne = next || current
            const p = pr
            const n = ne
            const smoothing = 0.2
            const o = this.line(p, n)
            const angle = o.angle + (reverse ? Math.PI : 0)
            const length = o.length * smoothing
            const x = current[0] + Math.cos(angle) * length
            const y = current[1] + Math.sin(angle) * length
            return [x, y]
        },
        line(pointA, pointB) {
            const lengthX = pointB[0] - pointA[0]
            const lengthY = pointB[1] - pointA[1]
            return {
                length: Math.sqrt(Math.pow(lengthX, 2) + Math.pow(lengthY, 2)),
                angle: Math.atan2(lengthY, lengthX),
            }
        },
        setDashoffset(element) {
            const pathLength = element.getTotalLength()
            element.style['stroke-dasharray'] = pathLength + ' ' + pathLength
            element.style['stroke-dashoffset'] = pathLength
            return pathLength
        },
        dist(x1, x2, y1, y2) {
            return Math.sqrt((x2 -= x1) * x2 + (y2 -= y1) * y2)
        },
    },
}
</script>

<template>
    <path :d="draw" :class="$style.path" />
</template>

<style lang="scss" module>
@import '@design';

.path {
    fill: none;
    stroke: var(--color-map-path);
    stroke-width: 0.3;
}
</style>
