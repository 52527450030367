<script>
export default {
    props: {
        padding: {
            type: Boolean,
            default: false,
        },
    },
}
</script>

<template>
    <div :class="[$style.column, padding ? $style.padding : '']">
        <slot />
    </div>
</template>

<style lang="scss" module>
.column {
    max-width: 50%;
    flex: 0 0 50%;
}
.padding {
    padding: 20px;
}
</style>
