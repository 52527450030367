<script>
import { mapGetters } from 'vuex'
import { calculateFinalTime } from '@utils/time'
import { calculateObstaclesFaults, calculateTimeFaults } from '@utils/faults'

export default {
    props: {
        competitor: {
            type: Object,
            required: true,
        },
    },
    computed: {
        ...mapGetters('session', ['timeToDisplay']),
        obstaclesFaults() {
            return this.timeToDisplay === null ? '-' : calculateObstaclesFaults(this.competitor)
        },
        timeFaults() {
            return this.timeToDisplay === null ? '-' : calculateTimeFaults(this.timeToDisplay)
        },
        faults() {
            return this.timeToDisplay === null ? '-' : this.obstaclesFaults + this.timeFaults
        },
        time() {
            return this.timeToDisplay === null ? '-' : calculateFinalTime(this.competitor)
        },
    },
}
</script>

<template>
    <div :class="$style.wrapper">
        <!-- MAIN -->
        <div :class="$style.main">
            <div :class="$style.mainTitle"> {{ $tc('faults', 2) }} </div>
            <div :class="$style.mainContent"> {{ faults }}<span :class="$style.points">pts</span> </div>
        </div>
        <!-- DETAILS -->
        <div :class="$style.details">
            <!-- DETAILS : obstacles -->
            <div :class="$style.detailsGroup">
                <div :class="$style.detailsTitle"> {{ $tc('obstacles', 2) }} </div>
                <div :class="$style.detailsContent"> {{ obstaclesFaults }}<span :class="$style.points">pts</span> </div>
            </div>
            <!-- DETAILS : time -->
            <div :class="$style.detailsGroup">
                <div :class="$style.detailsTitle"> {{ $t('times') }} </div>
                <div :class="$style.detailsContent"> {{ timeFaults }}<span :class="$style.points">pts</span> </div>
            </div>
        </div>
    </div>
</template>

<style lang="scss" module>
@import '@design';

.wrapper {
    display: flex;
    padding-bottom: 20px;
    margin-top: 20px;
}
.points {
    font-size: 0.4em;
    font-style: italic;
    font-weight: 400;
}
// MAIN
.main {
    flex: 1;
}
.mainTitle {
    margin-bottom: 5px;
    font-size: 0.9em;
    font-weight: 600;
    text-transform: uppercase;
    opacity: 0.8;
}
.mainContent {
    font-size: 2em;
    font-weight: 800;
}
// DETAILS
.details {
    display: flex;
}
.detailsGroup {
    display: flex;
    flex-direction: column;
    // justify-content: center;
    padding: 0 10px;
}
.detailsTitle {
    margin-bottom: 5px;
    font-size: 0.6em;
    font-weight: 600;
    text-transform: uppercase;
    opacity: 0.6;
}
.detailsContent {
    font-size: 1.6em;
    font-weight: 800;
}
</style>
