export const constantsCompetitorStatus = [
    {
        value: 0,
        text: 'NOT STARTED YET',
        timeEnabled: true,
    },
    {
        value: 1,
        text: 'FINISHED',
        timeEnabled: true,
    },
    {
        value: 2,
        text: 'ELIMINATED',
        timeEnabled: false,
    },
    {
        value: 3,
        text: 'RETIRED',
        timeEnabled: false,
    },
    {
        value: 4,
        text: 'WITHDRAWN',
        timeEnabled: false,
    },
    {
        value: 5,
        text: 'DISQUALIFIED',
        timeEnabled: false,
    },
    {
        value: 6,
        text: 'RUNNING',
        timeEnabled: true,
    },
    {
        value: 7,
        text: 'NOT STARTING',
        timeEnabled: false,
    },
]

export const getFEIphoto = (no_fei) => 'https://s3-eu-west-1.amazonaws.com/fei-bios/Images/' + no_fei + '/Profile.jpg'
