var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (this.pastCompetitors.length > 0)?_c('div',{class:_vm.$style.wrapper},[_c('div',{class:_vm.$style.statText},[_c('span',{domProps:{"innerHTML":_vm._s(
                _vm.$tc('competitors-out-of-time-txt-number', _vm.outOfTimeCompetitors.length, {
                    count: _vm.outOfTimeCompetitors.length,
                    maxTime: _vm.session.maxtime,
                }) + '. '
            )}}),_c('span',{domProps:{"innerHTML":_vm._s(
                _vm.$tc('competitors-out-of-time-txt-percent', _vm.outOfTimeCompetitors.length, {
                    percent: _vm.outOfTimeCompetitorsPercent,
                }) + '.'
            )}})]),_vm._l((_vm.outOfTimeCompetitors),function(competitor,key){return _c('ListCompetitor',{key:("competitor" + key),attrs:{"competitor":competitor,"stats":true,"maxTime":_vm.session.maxtime,"odd":key % 2 === 0,"qualified":parseInt(competitor.status) === 1 &&
                competitor.time !== null &&
                ((_vm.session.qualified_mode === 'no-faults' && competitor.totalFaults === 0) ||
                    (_vm.session.qualified_mode === 'x-first' && key < _vm.session.qualified_count))}})})],2):_c('BaseContainer',[_c('BaseEmpty',[_vm._v(" "+_vm._s(_vm.$t('no-stats'))+". ")])],1)}
var staticRenderFns = []

export { render, staticRenderFns }