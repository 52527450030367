export function formatTime(time) {
    return (time / 1000).toFixed(2)
}

export function pausesTime(datesPause) {
    const pausesFinished = datesPause.filter((pause) => pause.pauseFinish && pause.pauseStart)
    return pausesFinished.length
        ? pausesFinished.map((pause) => pause.pauseFinish - pause.pauseStart).reduce((accu, value) => accu + value)
        : 0
}

export function intermediatesTimesFromStart(competitor) {
    return competitor.timeIntermediate.map((pulse) => {
        return { time: (pulse.date - competitor.dateStart) / 1000, channel: pulse.channel }
    })
}

export function calculateRunningTime(competitor) {
    const start = competitor.dateStart
    const pauses = competitor.datesPause
    console.warn('start - Date.now()', Date.now() - start)
    console.warn('pausesTime(pauses)', pausesTime(pauses))
    return parseFloat(formatTime(Date.now() - start - pausesTime(pauses)))
}

export function calculateFinalTime(competitor) {
    const finish = competitor.dateFinish
    const start = competitor.dateStart
    const pauses = competitor.datesPause
    return parseFloat(formatTime(finish - start - pausesTime(pauses)))
}

// export function algeRawTimeToAlgeTime(algeRawTime) {
//     const exp = /(\d{1,2}):(\d{1,2}):(\d{1,2})\.(\d{0,3})/
//     const match = exp.exec(algeRawTime)
//     if (match !== null) {
//         let now = new Date()
//         now.setHours(parseInt(match[1]))
//         now.setMinutes(parseInt(match[2]))
//         now.setSeconds(parseInt(match[3]))
//         now.setMilliseconds(parseInt(match[4].padEnd(3, 0)))
//         return now.getTime()
//     }
//     return null
// }

export function algeRawTimeToAlgeTime(algeRawTime) {
    const regex = /^[0-9]{2}:[0-9]{2}:[0-9]{2}.[0-9]{1,4}$/
    let isValidAlgeTime = new RegExp(regex)
    if (isValidAlgeTime.test(algeRawTime)) {
        // this is a valid ALGE time
        let [time, ms] = algeRawTime.trim().split('.')
        const [hours, minutes, seconds] = time.split(':')
        const algeDateTime = new Date()
        algeDateTime.setHours(hours)
        algeDateTime.setMinutes(minutes)
        algeDateTime.setSeconds(seconds)
        ms = ms.padEnd(4, '0')
        ms = Math.round(ms / 10)
        algeDateTime.setMilliseconds(ms)
        return algeDateTime.getTime()
    }
    return null
}

export function sleep(ms) {
    return new Promise((resolve) => setTimeout(resolve, ms))
}

export function removeMsFromDateTime(date, toLocal = true) {
    const dateTime = new Date(date)

    return (
        (toLocal ? dateTime.getFullYear() : dateTime.getUTCFullYear()) +
        '-' +
        ('0' + ((toLocal ? dateTime.getMonth() : dateTime.getUTCMonth()) + 1)).slice(-2) +
        '-' +
        ('0' + (toLocal ? dateTime.getDate() : dateTime.getUTCDate())).slice(-2) +
        'T' +
        ('0' + (toLocal ? dateTime.getHours() : dateTime.getUTCHours())).slice(-2) +
        ':' +
        ('0' + (toLocal ? dateTime.getMinutes() : dateTime.getUTCMinutes())).slice(-2)
    )
}

export function dateTimeToDateAndTime(date, removeMs = true) {
    const splitedDate = removeMs ? removeMsFromDateTime(date) : splitedDate

    return {
        date: splitedDate.split('T')[0],
        time: splitedDate.split('T')[1],
    }
}
