<script>
export default {
    props: {
        session: {
            type: Object,
            required: true,
        },
    },
}
</script>

<template>
    <div :class="$style.wrapper">
        <!-- PULSE -->
        <div :class="$style.pulse" />
        <!-- TITLES -->
        <div :class="$style.titles">
            <div :class="$style.riderName">
                <div v-for="(partName, indexPartName) in session.name.split('-')" :key="indexPartName">
                    {{ partName.trim() }}
                </div>
            </div>
            <div :class="$style.horseName">
                {{ session.competition.name }}
            </div>
        </div>
        <!-- MESSAGE -->
        <div :class="$style.message">
            <div> {{ $t('class-doesnt-start') }}.</div>
            <BaseButton :to="{ name: 'sessions' }" :class="$style.messageButton">
                {{ $t('back-to-classes') }}
            </BaseButton>
        </div>
    </div>
</template>

<style lang="scss" module>
@import '@design';

.wrapper {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: rgba(black, 0.9);
}
.messageButton {
    margin-top: 10px;
}

// PULSE
.pulse {
    display: block;
    width: 10px;
    height: 10px;
    margin-bottom: 20px;
    background: var(--color-link);
    border-radius: $border-radius-round;
    box-shadow: 0 0 0 5px var(--color-link);
    opacity: 0;
    transform: scale(0);
    animation: image 1.5s ease-out forwards, pulse 3s ease-out forwards infinite, scale 3s linear infinite;
}

@keyframes image {
    to {
        opacity: 1;
        transform: scale(1);
    }
}

@keyframes pulse {
    to {
        box-shadow: 0 0 0 150px var(--color-blue-lighter);
    }
}

@keyframes scale {
    0% {
        transform: scale(1.05);
    }
    10% {
        transform: scale(1);
    }
    95% {
        transform: scale(1);
    }
    100% {
        transform: scale(1.05);
    }
}

// TITLES
.titles {
    text-align: center;
    color: var(--color-secondary);
}
.riderName {
    @include font-heading;

    font-size: 2em;
    opacity: 0;
    transform: translateX(-100%);
}
.horseName {
    margin-top: 3px;
    font-size: 1.2em;
    font-weight: 400;
    opacity: 0;
    transform: translateX(100%);
}
.riderName,
.horseName {
    animation: names 1s ease forwards;
    animation-delay: 1.5s;
}

@keyframes names {
    to {
        opacity: 1;
        transform: translateX(0);
    }
}

// Start Number
.message {
    margin-top: 60px;
    font-size: 16px;
    text-align: center;
    opacity: 0;
    transform: translateY(-100%);
    animation: date 500ms ease forwards;
    animation-delay: 2.5s;
    color: var(--color-secondary);
}

@keyframes date {
    to {
        opacity: 1;
        transform: translateY(0);
    }
}
</style>
