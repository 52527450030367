<script>
export default {
    props: {
        data: {
            type: Array,
            required: true,
        },
        to: {
            type: Object,
            default: () => {},
        },
    },
    methods: {
        getTo(item) {
            return this.to
                ? {
                      name: this.to.name,
                      params: {
                          id: item[this.to.param],
                      },
                  }
                : {}
        },
    },
}
</script>

<template>
    <tbody :class="$style.tbody">
        <component
            :is="to ? 'BaseLink' : 'tr'"
            v-for="(item, key) in data"
            :key="`body${key}`"
            :to="getTo(item)"
            tag="tr"
        >
            <slot :item="item" />
        </component>
    </tbody>
</template>

<style lang="scss" module>
@import '@design';

.tbody {
    td,
    th {
        padding: 1rem;
        padding-right: 1.5rem;
        padding-left: 1.5rem;
        font-size: 0.8125rem;
        vertical-align: middle;
        border-top: 1px solid var(--color-text);
    }
    tr:first-child {
        td,
        th {
            border-top: 0;
        }
    }
}
</style>
