<script>
export default {
    props: {
        animate: {
            type: Boolean,
            default: false,
        },
    },
}
</script>

<template>
    <g :class="[$style.startPennant, animate ? $style.started : $style.notStarted]">
        <path
            d="
                M -0.1 0
                h 0.1 v 7
                H -0.1 z"
        />
        <path d="M 0,0 V 2 L 2,1 z" />
        <path d="M 0,5 V 7 L 2,6 z" />
    </g>
</template>

<style lang="scss" module>
@import '@design';

.startPennant path {
    stroke: black;
    stroke-width: 0.1;
}

.notStarted path {
    &:nth-child(2) {
        fill: var(--color-red);
    }
    &:last-child {
        fill: var(--color-text-white);
    }
}

.started path {
    &:nth-child(2) {
        animation: fadeFromRed 2s 1;
        animation-fill-mode: forwards;
    }
    &:last-child {
        animation: fadeFromWhite 2s 1;
        animation-fill-mode: forwards;
    }
}

@keyframes fadeFromRed {
    from {
        fill: var(--color-red);
    }
    to {
        fill: var(--color-green);
    }
}

@keyframes fadeFromWhite {
    from {
        fill: var(--color-text-white);
    }
    to {
        fill: var(--color-green);
    }
}
</style>
