<script>
export default {
    props: {
        status: {
            type: Boolean,
            required: true,
        },
    },
}
</script>

<template>
    <span :class="$style.wrapper">
        <div :class="[$style.dot, status ? $style.dotGreen : $style.dotRed]" />
        <span :class="$style.status">
            {{ status ? 'En cours' : 'Terminé' }}
        </span>
    </span>
</template>

<style lang="scss" module>
@import '@design';

.wrapper {
    display: flex;
    align-items: center;
}
.dot {
    display: block;
    width: 0.375rem;
    height: 0.375rem;
    margin-right: 0.375rem;
    border-radius: 50%;
    background: red;
    &.dotGreen {
        background: var(--color-success);
    }
    &.dotRed {
        background: var(--color-error);
    }
}
</style>
