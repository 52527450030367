<script>
import { mapState, mapMutations } from 'vuex'

export default {
    props: {
        jumps: {
            type: Array,
            default: () => [],
        },
    },
    data() {
        return {
            mousePosition: null,
            mousePreviousPosition: null,
        }
    },
    computed: {
        ...mapState('session/editor', {
            canvas: (state) => state.canvas,
            selected: (state) => state.selected,
            dragging: (state) => state.dragging,
        }),
    },
    mounted() {
        document.body.addEventListener('mousemove', this.onMouseMove)
        document.body.addEventListener('mouseup', this.onMouseUp)
    },
    beforeDestroy() {
        document.body.removeEventListener('mousemove', this.onMouseMove)
        document.body.removeEventListener('mouseup', this.onMouseUp)
    },
    methods: {
        ...mapMutations('session/editor', ['SET_DRAGGING']),
        onMouseDown(jump) {
            if (this.isSelectedJump(jump.id)) {
                this.SET_DRAGGING(true)
                this.setMousePosition()
            }
        },
        onMouseUp() {
            // If no dragging, do nothing
            if (!this.dragging) return

            this.SET_DRAGGING(false)
            this.mousePosition = null
        },
        onMouseMove(event) {
            if (this.dragging) {
                this.setMousePosition()
                this.setSelectedPosition()
            }
        },
        setSelectedPosition() {
            for (var i = this.selected.length - 1; i >= 0; i--) {
                let selectedId = this.selected[i]
                let jump = this.$refs[selectedId][0]
                let current = {
                    x: parseFloat(jump.getAttribute('x')),
                    y: parseFloat(jump.getAttribute('y')),
                }
                let drift = {
                    x: this.mousePosition.x - this.mousePreviousPosition.x,
                    y: this.mousePosition.y - this.mousePreviousPosition.y,
                }
                let newPosition = {
                    x: current.x + drift.x,
                    y: current.y + drift.y,
                }
                newPosition.x = newPosition.x <= 0 ? 0 : newPosition.x
                newPosition.x = newPosition.x >= this.canvas.viewbox.width ? this.canvas.viewbox.width : newPosition.x
                newPosition.y = newPosition.y <= 0 ? 0 : newPosition.y
                newPosition.y = newPosition.y >= this.canvas.viewbox.height ? this.canvas.viewbox.height : newPosition.y
                jump.setAttribute('x', newPosition.x)
                jump.setAttribute('y', newPosition.y)
            }
        },
        setMousePosition() {
            if (this.mousePosition) {
                this.mousePreviousPosition = {
                    x: this.mousePosition.x,
                    y: this.mousePosition.y,
                }
            }
            const canvas = this.canvas
            let x = event.clientX - canvas.left
            let y = event.clientY - canvas.top
            x = (x * canvas.viewbox.width) / canvas.width
            y = (y * canvas.viewbox.height) / canvas.height
            this.mousePosition = {
                x,
                y,
            }
        },
        isSelectedJump(id) {
            return this.selected.some((selectId) => selectId === id)
        },
    },
}
</script>

<template>
    <g>
        <svg
            v-for="jump in jumps"
            :key="jump.id"
            :ref="jump.id"
            :x="jump.x"
            :y="jump.y"
            :class="[$style.wrapper, dragging ? $style.dragging : '', !jump.visible ? $style.jumpHidden : '']"
            @mousedown="onMouseDown(jump)"
        >
            <!--
        Precision Point when dragging
      -->
            <circle :class="$style.precisionPoint" />
            <!--
        Jumps
      -->
            <circle
                :class="[
                    $style.jump,
                    isSelectedJump(jump.id) ? $style.jumpSelected : '',
                    dragging && isSelectedJump(jump.id) ? $style.jumpDragged : '',
                ]"
            />
            <!--
        Rotate marker
      -->
            <line
                x1="0"
                :y1="isSelectedJump(jump.id) ? -1.25 : -1"
                x2="0"
                :y2="isSelectedJump(jump.id) ? 1.25 : 1"
                :class="[$style.rotateMarker, dragging && isSelectedJump(jump.id) ? $style.rotateMarkerDragged : '']"
            />
            <!--
        Name
      -->
            <text :class="$style.text">
                {{ jump.name }}
            </text>
        </svg>
    </g>
</template>

<style lang="scss" module>
@import '@design';

.wrapper {
    @include unselectable;

    overflow: visible;
    cursor: pointer;
    &.dragging {
        cursor: none;
    }
}
.jump {
    fill: var(--color-blue);
    stroke: var(--color-blue-light);
    stroke-width: 1;
    transition: transform 100ms ease-out;
    transform: translateY(0);
}
.jumpSelected {
    fill: var(--color-red);
    stroke: var(--color-red-lighter);
}
.jumpDragged {
    transform: translateY(-3px);
}
.jumpHidden {
    opacity: 0;
}
.precisionPoint {
    fill: var(--color-red);
}
.rotateMarker {
    stroke: white;
    stroke-width: 0.2;
    transition: transform 100ms ease-out;
    &.rotateMarkerDragged {
        transform: translateY(-3px);
    }
}
.text {
    @include unselectable;

    font-size: 1.5px;
    text-anchor: middle;
    fill: white;
    transform: translate(0, 4.5%);
}
</style>
