import store from '@state/store'
import { rounds } from '@constants/session'

export function calculateObstaclesFaults(competitor) {
    let faults = 0
    if (competitor.jumps?.length > 0) {
        faults = competitor.jumps
            .map((jump) => jump.status.filter((statu) => statu > 0).length)
            .reduce((accu, value) => accu + value)
        faults *= 4
    }
    const previousFaults = getPreviousFaults(competitor)
    if (previousFaults !== false) {
        faults += previousFaults
    }
    return faults
}

export function calculateTimeFaults(time) {
    const upwardTime = Math.ceil(time)
    const maxtime = store.state.session.session.maxtime
    const isJumpOff = store.state.session.session.round === 3
    const faultCountBySecond = 1 // Untill 2022 it was 4

    if (isJumpOff) {
        return time > maxtime ? upwardTime - maxtime : 0 // Upward is an integer
    } else {
        const timeDiff = Math.ceil((upwardTime - maxtime) / faultCountBySecond)
        return time > maxtime ? timeDiff : 0
    }
}

export function getPreviousFaults(competitor) {
    if (store.state.session.session?.parent) {
        const round = rounds.find((r) => r.value === store.state.session.session.round)
        if (round?.cumulateFaults) {
            const previsousResults = store.state.session.session.parent.competitors.find(
                (c) => c.cnr === competitor.cnr
            )
            if (previsousResults?.totalFaults) {
                return parseFloat(previsousResults?.totalFaults)
            }
            return 0
        }
    }
    return false
}

export function getPreviousTime(competitor) {
    //there is a previous manche but this is not a jumpoff
    const round = rounds.find((r) => r.value === store.state.session.session.round)
    if (store.state.session.session?.parent && round?.cumulateFaults) {
        const previsousResults = store.state.session.session.parent.competitors.find((c) => c.cnr === competitor.cnr)
        return previsousResults?.time
    }
    return false
}
