import Vue from 'vue'
import http from '@state/http'
import sortBy from 'lodash/sortBy'
import orderBy from 'lodash/orderBy'
import decimal from '@components/filters/decimal'
import { getPreviousTime } from '@utils/faults'
import { rounds } from '@constants/session'
import { getFEIphoto } from '@constants/competitor'
import { clone } from '@state/helpers'

export const state = {
    sessions: [],
    session: {},
    counter: null,
    provisonialTime: 0,
}

export const mutations = {
    SET_SESSIONS(state, newSessions) {
        state.sessions = newSessions
    },
    SET_SESSION(state, newSession) {
        state.session = newSession
    },
    EDIT_SESSION(state, newValues) {
        Object.keys(newValues).forEach((key) => {
            console.warn('foreach', key, newValues[key])
            Vue.set(state.session, key, newValues[key])
        })
    },
    SET_TIME(state, time) {
        state.provisonialTime = time
    },
    SET_COUNTER(state, counter) {
        state.counter = counter
    },
}

export const getters = {
    nextCompetitors(state) {
        const competitors = state.session
            ? state.session.competitors.filter(
                  (competitor) => competitor.status === 0 && competitor.id !== state.session.current_competitor_id
              )
            : []
        return sortBy(competitors, ['startNumber'])
    },
    currentCompetitor(state) {
        return state.session
            ? state.session.competitors.find((competitor) => competitor.id === state.session.current_competitor_id)
            : {}
    },
    pastCompetitors(state) {
        const competitors = state.session
            ? clone(state.session.competitors)
                  .filter(
                      (competitor) => competitor.status !== 0 && competitor.id !== state.session.current_competitor_id
                  )
                  .map((competitor) => {
                      competitor.pastTime = getPreviousTime(competitor)
                      return competitor
                  })
            : []
        const round = rounds.find((r) => r.value === state.session.round)
        if (round.includePreviousTimeRanking) {
            return sortBy(competitors, ['status', 'totalFaults', 'pastTime', 'time'])
        } else {
            return sortBy(competitors, ['status', 'totalFaults', 'time'])
        }
    },
    pastCompetitorsFinished(state) {
        const competitors = state.session
            ? clone(state.session.competitors)
                  .filter(
                      (competitor) => competitor.status === 1 && competitor.id !== state.session.current_competitor_id
                  )
                  .map((competitor) => {
                      competitor.pastTime = getPreviousTime(competitor)
                      return competitor
                  })
            : []
        const round = rounds.find((r) => r.value === state.session.round)
        if (round.includePreviousTimeRanking) {
            return sortBy(competitors, ['status', 'totalFaults', 'pastTime', 'time'])
        } else {
            return sortBy(competitors, ['status', 'totalFaults', 'time'])
        }
    },
    firstCompetitor(state, getters) {
        return getters.pastCompetitors[0]
    },
    rankCompetitor: (state, getters) => (idCompetitor) => {
        const finishedCompetitor = getters.allCompetitors.filter((competitor) => competitor.status === 1)
        if (finishedCompetitor.length === 0) {
            return null
        }
        const rank = finishedCompetitor.findIndex((competitor) => competitor.id === idCompetitor)
        if (rank === -1) {
            return null
        } else {
            const competitor = finishedCompetitor[rank]
            if (competitor.jumps && competitor.jumps.length) {
                const lastJump = competitor.jumps[competitor.jumps.length - 1]
                if (lastJump.status.length) {
                    return rank + 1
                } else {
                    return null
                }
            } else {
                return rank + 1
            }
        }
    },
    timeToDisplay(state, getters) {
        return !getters.currentCompetitor.dateStart ||
            getters.currentCompetitor.dateFinish ||
            getters.currentCompetitor.is_time_pause
            ? getters.currentCompetitor.time
            : parseFloat(state.provisonialTime).toFixed(1)
    },
    outOfTimeCompetitors(state) {
        const competitors = state.session
            ? state.session.competitors.filter(
                  (competitor) =>
                      competitor.status === 1 &&
                      competitor.id !== state.session.current_competitor_id &&
                      competitor.time > parseFloat(state.session.maxtime)
              )
            : []
        return sortBy(competitors, ['number'])
    },
    allCompetitors(state) {
        const competitors = state.session
            ? clone(state.session.competitors).map((competitor) => {
                  competitor.pastTime = getPreviousTime(competitor)
                  return competitor
              })
            : []
        const round = rounds.find((r) => r.value === state.session.round)
        if (round.includePreviousTimeRanking) {
            return sortBy(competitors, ['status', 'totalFaults', 'pastTime', 'time'])
        } else {
            return sortBy(competitors, ['status', 'totalFaults', 'time'])
        }
    },
    knockedJumps(state) {
        if (state.session) {
            return orderBy(state.session.statistics.knockedJumps, ['knockedCount'], ['desc'])
        }
        return []
    },
    mostKnockedJumps: (state) => (xFirst = 1) => {
        let mostKnockedJumpsResult = []
        const knockedJumps = orderBy(state.session.statistics.knockedJumps, ['knockedCount'], ['desc'])

        for (let cntJump = 0; cntJump < knockedJumps.length; cntJump++) {
            mostKnockedJumpsResult.push(knockedJumps[cntJump])
            for (let cntNextJump = cntJump + 1; cntNextJump < knockedJumps.length; cntNextJump++) {
                if (knockedJumps[cntJump].knockedCount === knockedJumps[cntNextJump].knockedCount) {
                    mostKnockedJumpsResult.push(knockedJumps[cntNextJump])
                    cntJump++
                } else {
                    break
                }
            }
            if (mostKnockedJumpsResult.length >= xFirst) break
        }

        return mostKnockedJumpsResult
    },
}

export const actions = {
    FETCH_SESSIONS({ commit }) {
        return http
            .get('/live/sessions', {
                params: { competition: true, theme: Vue.prototype.$theme },
            })
            .then((response) => {
                const sessions = response.data
                commit('SET_SESSIONS', sessions)
                return sessions
            })
    },
    FETCH_SESSION({ commit, state }, id) {
        return http
            .get(`/live/session/${id}`)
            .then((response) => {
                const session = response.data
                session.competitors = session.competitors.map((competitor) => {
                    if (competitor.time !== null) competitor.time = parseFloat(competitor.time)
                    if (competitor.faults !== null) competitor.faults = parseFloat(decimal(competitor.faults, 0))
                    if (competitor.timeFaults !== null)
                        competitor.timeFaults = parseFloat(decimal(competitor.timeFaults, 0))
                    if (competitor.totalFaults !== null)
                        competitor.totalFaults = parseFloat(decimal(competitor.totalFaults, 0))

                    if (!competitor.photo) competitor.photo = getFEIphoto(competitor.no_fei)

                    return competitor
                })

                commit('SET_SESSION', session)
                return session
            })
            .catch((error) => {
                return false
            })
    },
    START_COUNTER({ dispatch, state, commit }, time) {
        console.log('START_COUNTER')
        commit('SET_TIME', parseFloat(time))
        if (state.counter) return //prevent duplicate start
        let counter = setInterval(() => {
            commit('SET_TIME', state.provisonialTime + 0.1)
        }, 100)
        commit('SET_COUNTER', counter)
    },
    STOP_COUNTER({ dispatch, state, commit }, time) {
        if (state.counter) clearInterval(state.counter)
        commit('SET_COUNTER', null)
        commit('SET_TIME', time === null ? time : parseFloat(time))
    },
    RESET_TIME({ dispatch, state, commit }, time) {
        if (state.counter) clearInterval(state.counter)
        commit('SET_COUNTER', null)
        commit('SET_TIME', 0)
    },
}
