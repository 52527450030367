<script>
import { intermediatesTimesFromStart } from '@utils/time'
import { mapGetters } from 'vuex'
import CompetitorSpeeds from '@components/competitor-speeds'

export default {
    components: {
        CompetitorSpeeds,
    },
    props: {
        currentCompetitor: {
            type: Object,
            required: true,
        },
        pastCompetitorsFinished: {
            type: Array,
            default: () => [],
        },
        maxtime: {
            type: Number,
            required: true,
        },
        timetobeat: {
            type: [Number, String],
            required: false,
        },
        currentCompetitorId: {
            type: String,
            required: true,
        },
        displayFinalDiff: {
            type: Boolean,
            default: true,
        },
    },
    computed: {
        ...mapGetters('session', ['timeToDisplay']),
        bestCompetitor() {
            return this.pastCompetitorsFinished[0] || null
        },
        intermediates() {
            const times = this.currentCompetitor.timeIntermediate
            if (times.length && this.pastCompetitorsFinished.length) {
                const currentIntermediates = intermediatesTimesFromStart(this.currentCompetitor)
                const bestIntermediates = intermediatesTimesFromStart(this.bestCompetitor)

                return bestIntermediates
                    .map((bestTimePulse) => {
                        const currentTimePulse = currentIntermediates.find(
                            (pulse) => pulse.channel === bestTimePulse.channel
                        )

                        if (currentTimePulse)
                            return (Math.trunc((currentTimePulse.time - bestTimePulse.time) * 100) / 100).toFixed(2)
                    })
                    .filter((time) => time < 60 && time > -60)
            } else {
                return []
            }
        },
        final() {
            return this.bestCompetitor && this.currentCompetitor.status === 1 && this.displayFinalDiff
                ? (parseFloat(this.currentCompetitor.time) - parseFloat(this.bestCompetitor.time)).toFixed(2)
                : null
        },
        speeds() {
            let speedsList = []

            this.currentCompetitor.speed.forEach((speedPulse) => {
                const previousCell = this.currentCompetitor.pulsations.find(
                    (pulse) => pulse.channel === speedPulse.previousChannel
                )

                if (previousCell) {
                    const speed = (3.6 * speedPulse.distance) / ((speedPulse.date - previousCell.date) / 1000)
                    const result = Math.round(speed * 100) / 100

                    if (result > 5 && result < 100) return speedsList.push(result) // Only positive values > 5
                }
            })

            return speedsList
        },
    },
}
</script>

<template>
    <div :class="$style.wrapper">
        <!-- LEFT -->
        <div :class="$style.time">
            <div>
                <div :class="$style.timeTitle"> {{ $t('times') }} </div>
                <div v-if="timeToDisplay !== null" :class="$style.times">
                    <div :class="$style.timeContent">
                        {{ timeToDisplay }}
                    </div>
                    <div v-show="final" :class="[$style.final, final <= 0 ? $style.before : $style.after]">
                        {{ final > 0 ? `+${final}` : final }}
                    </div>
                </div>
                <div v-else :class="$style.timeContent">
                    -
                </div>
                <div v-show="timetobeat" :class="$style.timeToBeat">
                    {{ timetobeat }}<span>s </span>{{ $t('timetobeat') }}
                </div>
                <div
                    v-show="timeToDisplay > (maxtime * 80) / 100"
                    :class="[$style.timeMax, timetobeat ? $style.moveOnRigth : '']"
                >
                    {{ maxtime }}<span>s </span>{{ $t('maximum') }}
                </div>
            </div>
        </div>

        <!-- RIGHT -->
        <div :class="$style.right">
            <div v-if="intermediates.length" :class="[$style.intermediateLabel, $style.timeTitle]">
                {{ $t('split-time') }}
            </div>
            <div :class="$style.intermediates">
                <div v-for="(intermediate, key) in intermediates" :key="`intermediate${key}`">
                    <div :class="[$style.intermediate, intermediate <= 0 ? $style.before : $style.after]">{{
                        intermediate > 0 ? `+${intermediate}` : intermediate
                    }}</div>
                </div>
            </div>

            <div v-if="speeds && speeds.length > 0" :class="$style.speed">
                <div :class="$style.timeTitle"> {{ $t('speed') }} </div>
                <CompetitorSpeeds :speeds="speeds" :class="$style.speeds" />
            </div>
        </div>
    </div>
</template>

<style lang="scss" module>
@import '@design';

.wrapper {
    display: flex;
    justify-content: space-between;
}
// TIME
.time {
    display: flex;
}
.timeTitle {
    font-size: 0.9em;
    font-weight: 600;
    text-transform: uppercase;
    opacity: 0.8;
}
.times {
    display: flex;
}
.timeContent {
    font-size: 2em;
    font-weight: 800;
}
.timeToBeat {
    display: inline-block;
    padding: 3px;
    margin-top: 3px;
    font-size: 0.7em;
    font-weight: 800;
    color: var(--color);
    text-transform: uppercase;
    background: white;
    border-radius: 2px;
    transition: all 1s ease-out;
    span {
        font-size: 0.7em;
        opacity: 0.8;
    }
}
.timeMax {
    display: inline-block;
    padding: 3px;
    margin-top: 3px;
    font-size: 0.7em;
    font-weight: 800;
    color: black;
    text-transform: uppercase;
    background: var(--color-red);
    border-radius: 2px;
    transition: all 1s ease-out;
    span {
        font-size: 0.7em;
        opacity: 0.8;
    }

    &.moveOnRigth {
        margin-left: 8px;
    }
}
.final {
    margin-left: 10px;
    padding: 5px;
    font-size: 12px;
    border-radius: $border-radius;
    white-space: nowrap;
    align-self: center;

    &.before {
        color: var(--color-intermediate-before);
        border: 1px solid var(--color-intermediate-before);
    }
    &.after {
        color: var(--color-red);
        border: 1px solid var(--color-red);
    }
}

// RIGHT
.right {
    display: flex;
    flex-direction: column;
    white-space: nowrap;
}
.intermediates {
    display: flex;
    align-self: stretch;
    justify-content: flex-end;

    > div:not(:first-child) {
        margin-left: 5px;
    }
}
.intermediate {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px;
    border-radius: 5px;
    height: 30px;
    // text
    font-family: var(--font-family)-heading;
    font-size: 1em;
    font-weight: 900;
    color: var(--color-secondary);
    &:not(:first-child) {
        margin-left: 3px;
    }
    color: var(--color-background-darkest);

    &.before {
        background-color: var(--color-intermediate-before);
    }
    &.after {
        background-color: var(--color-red);
    }
}
.intermediateLabel {
    margin-bottom: 3px;
}
.speed {
    margin-top: 10px;
    display: flex;
    justify-content: flex-end;
    height: 20px;
    line-height: 20px;
}
.speeds {
    margin-left: 10px;
}
</style>
