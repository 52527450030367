import Vue from 'vue'
import router from '@router'
import 'pusher-js'
import Echo from 'laravel-echo'
import VueCroppie from 'vue-croppie'
import PortalVue from 'portal-vue'

Vue.use(VueCroppie)
Vue.use(PortalVue)

window.Echo = new Echo({
    broadcaster: 'pusher',
    key: process.env.VUE_APP_PUSHER_KEY,
    cluster: process.env.VUE_APP_PUSHER_CLUSTER,
    forceTLS: true,
})
