<script>
export default {
    props: {
        lists: {
            type: Array,
            required: true,
        },
        selectable: {
            type: Boolean,
            default: false,
        },
        link: {
            type: Object,
            default: null,
        },
    },
    methods: {
        getTo(list) {
            if (!this.link) {
                return
            }
            let to = {
                name: this.link.name,
                params: {},
            }
            to.params[this.link.param] = list[this.link.param]
            return to
        },
    },
}
</script>

<template>
    <div v-if="lists.length">
        <component
            :is="link ? 'RouterLink' : 'div'"
            v-for="(list, key) in lists"
            :key="`session${key}`"
            :to="getTo(list)"
            :class="$style.list"
        >
            <slot :list="list">
                <div v-show="selectable" :class="$style.selectable">
                    <div :class="$style.selectableButton" @click="$emit('selectable', key)" />
                </div>
                <div :class="$style.titles">
                    <div :class="$style.title">
                        {{ list.title }}
                    </div>
                    <div :class="$style.subtitle">
                        {{ list.subtitle }}
                    </div>
                </div>
                <div :class="$style.action">
                    <slot name="action" />
                </div>
            </slot>
        </component>
    </div>
    <div v-else :class="$style.noResult">
        <div :class="$style.noResultTitle"> {{ $t('oops') }} </div>
        <div :class="$style.noResultSubtitle"> {{ $t('no-result') }}</div>
    </div>
</template>

<style lang="scss" module>
@import '@design';

.list {
    display: flex;
    align-items: center;
    background: rgba(255, 255, 255, 0.08);
    border-radius: 3px;
    &:not(:first-child) {
        margin-top: 10px;
    }
}
.selectable {
    position: relative;
    z-index: 1px;
    display: flex;
    align-items: center;
    align-self: stretch;
    padding: 0 15px;
    margin-right: -15px;
    .selectableButton {
        width: 15px;
        height: 15px;
        border: 1px solid white;
        border-radius: $border-radius-round;
    }
}
.titles {
    flex: 1;
    padding: 15px;
    font-size: 16px;
    font-weight: 500;
    .title {
        font-weight: 800;
    }
    .subtitle {
        font-size: 12px;
    }
}
.action {
    padding: 15px;
}
.noResult {
    padding: 20px;
    color: var(--color-important);
    background: var(--color-important-light);
}
.noResultTitle {
    margin-bottom: 3px;
    font-size: 18px;
    font-weight: 700;
}
.noResultSubtitle {
    font-size: 16px;
}
</style>
