<script>
import TweenLite from 'gsap/TweenLite'

export default {
    props: {
        finalTime: {
            type: Number,
            default: 0,
        },
    },
    data() {
        return {
            time: 0,
            timer: 0,
            tweenedTime: 0,
        }
    },
    computed: {
        animatedTime() {
            return this.tweenedTime.toFixed(2)
        },
    },
    watch: {
        time(tweenedTime) {
            TweenLite.to(this.$data, 0.5, {
                tweenedTime,
            })
        },
    },
    methods: {
        startTime(timeStarted) {
            this.resetTime()
            this.timer = setInterval(() => {
                var elapsedTime = Date.now() - timeStarted
                this.time = (elapsedTime / 1000).toFixed(2)
            }, 10)
        },
        resetTime() {
            clearInterval(this.timer)
        },
    },
}
</script>

<template>
    <div>
        {{ finalTime || animatedTime }}
    </div>
</template>
