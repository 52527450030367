<script>
export default {
    props: {
        width: {
            type: Number,
            default: 100,
        },
        height: {
            type: Number,
            default: 75,
        },
    },
    computed: {
        viewBox() {
            return `${0} ${0} ${this.width} ${this.height}`
        },
    },
}
</script>

<template>
    <svg ref="canvas" :viewBox="viewBox" preserveAspectRatio="xMidYMid meet" :class="$style.canvas">
        <slot />
    </svg>
</template>

<style lang="scss" module>
.canvas {
    position: relative;
    z-index: 4;
    width: 100%;
    max-height: 600px;
}
</style>
