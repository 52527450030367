<script>
import { mapGetters } from 'vuex'
import ListCompetitor from '@components/list-competitor'

export default {
    components: {
        ListCompetitor,
    },
    props: {
        session: {
            type: Object,
            required: true,
        },
    },
    computed: {
        ...mapGetters('session', ['nextCompetitors']),
    },
}
</script>

<template>
    <BaseContainer>
        <div v-if="nextCompetitors.length">
            <ListCompetitor
                v-for="(competitor, key) in nextCompetitors"
                :key="`competitor${key}`"
                :competitor="competitor"
                :odd="key % 2 === 0"
                :qualified="
                    parseInt(competitor.status) === 1 &&
                        competitor.time !== null &&
                        ((session.qualified_mode === 'no-faults' && competitor.totalFaults === 0) ||
                            (session.qualified_mode === 'x-first' && key < session.qualified_count))
                "
            />
        </div>
        <BaseEmpty v-else>
            {{ $t('no-competitor') }}
            <template #description>
                {{ $t('all-competitor-already-passed') }}.
            </template>
        </BaseEmpty>
    </BaseContainer>
</template>
