import Cleave from 'cleave.js'

const requireModule = require.context(
    // Search for files in the current directory.
    'cleave.js/dist/addons/',
    // Search for files in subdirectories.
    false,
    // Include any .js files that are not this file or a unit test.
    /^((?!index|\.unit\.).)*\.js$/
)
const addons = requireModule.keys().map((fileName) => fileName.split('.')[2])

export default {
    bind: function (el, binding, vnode) {
        const element = el.tagName === 'INPUT' ? el : el.querySelector('input')

        // phone
        if (binding.modifiers.phone) {
            const phoneRegionCode = addons.indexOf(binding.value) > -1 ? binding.value : 'ch'
            import(`cleave.js/dist/addons/cleave-phone.${phoneRegionCode}`).then(() => {
                return mask(
                    element,
                    {
                        phone: true,
                        phoneRegionCode,
                    },
                    element.value
                )
            })
        }

        // date
        if (binding.modifiers.date) {
            return mask(
                element,
                {
                    date: true,
                    datePattern: ['d', 'm', 'Y'],
                },
                element.value
            )
        }
    },
}

function mask(element, options, value) {
    let cleave = new Cleave(element, options)
    cleave.setRawValue(value)
    return cleave
}
