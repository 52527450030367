<script>
import { mapGetters } from 'vuex'
// Map
import MapCanvas from '@components/map-canvas'
import MapItem from '@components/map-item'
import MapAsset from '@components/map-asset'
import MapStart from '@components/map-start'
import MapEnd from '@components/map-end'
import MapPath from '@components/map-path'
import MapJump from '@components/map-jump'
import MapJumpLiverpool from '@components/map-jump-liverpool'
import MapJumpFault from '@components/map-jump-fault'
// Competitor
import CompetitorInfos from '@components/competitor-infos'
import CompetitorTimes from '@components/competitor-times'
import CompetitorFaults from '@components/competitor-faults'
import CompetitorRank from '@components/competitor-rank'
import CompetitorJumps from '@components/competitor-jumps'
// List
import ListCompetitor from '@components/list-competitor'

import WebRTCPlayer from '@plugins/WebRTCplayer'
import { constantsCompetitorStatus } from '@constants/competitor'

export default {
    components: {
        WebRTCPlayer,
        // Map
        MapCanvas,
        MapItem,
        MapAsset,
        MapStart,
        MapEnd,
        MapPath,
        MapJump,
        MapJumpLiverpool,
        MapJumpFault,
        // Competitor
        CompetitorInfos,
        CompetitorTimes,
        CompetitorFaults,
        CompetitorRank,
        CompetitorJumps,
        // List
        ListCompetitor,
    },
    props: {
        session: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            animateEndItem: false,
        }
    },
    computed: {
        ...mapGetters('session', [
            'allCompetitors',
            'currentCompetitor',
            'pastCompetitors',
            'pastCompetitorsFinished',
            'mostKnockedJumps',
        ]),
        status() {
            return constantsCompetitorStatus.find((status) => status.value === this.currentCompetitor.status)
        },
        threeMostKnockedJump() {
            return this.mostKnockedJumps(3)
        },
        jumpFaults() {
            return this.currentCompetitor
                ? this.session.jumps.map((sessionJump) => {
                      const competitorJumps = this.currentCompetitor.jumps
                      const competitorJump = competitorJumps.find(
                          (competitorJump) => competitorJump.id === sessionJump.id
                      )
                      return competitorJump
                          ? {
                                ...sessionJump,
                                status: competitorJump.status,
                            }
                          : sessionJump
                  })
                : []
        },
        displayFinalDiff() {
            return (
                this.session.round === 3 ||
                (this.session.round === 1 && this.session.children.length === 0) ||
                (this.session.round === 1 &&
                    this.session.children.find((childSession) => childSession.round === 2) !== undefined) ||
                this.session.round === 4 ||
                (this.session.round === 2 &&
                    this.session.children.find((childSession) => childSession.round === 3) === undefined)
            )
        },
    },
    watch: {
        currentCompetitor: {
            handler: function(newValue, oldValue) {
                if (newValue?.status !== oldValue?.status) {
                    if (newValue?.status === 1) this.animateEndItem = true
                }
            },
            deep: true,
        },
    },
}
</script>

<template>
    <div :class="$style.wrapper">
        <!-- MAP -->
        <MapCanvas :width="session.canvasWidth" :height="session.canvasHeight" :class="$style.map">
            <!-- MAP : assets -->
            <MapItem :items="session.assets" v-slot="{ item: asset }">
                <MapAsset :asset="asset" />
            </MapItem>
            <!-- MAP : paths -->
            <MapPath :paths="session.paths" />
            <!-- MAP : jumps -->
            <MapItem :items="session.jumps" v-slot="{ item: jump }" :mostKnockedJumps="threeMostKnockedJump">
                <MapJumpLiverpool v-if="jump.jumpType.includes('liverpool')" />
                <MapJump :jump="jump" />
            </MapItem>
            <!-- MAP : jumpFaults -->
            <MapItem :items="jumpFaults" v-slot="{ item: jumpFault }" :displayText="false">
                <MapJumpFault :jump="jumpFault" :mostKnockedJumps="threeMostKnockedJump" />
            </MapItem>
            <!-- MAP : start -->
            <MapItem :items="session.starts">
                <MapStart :animate="currentCompetitor ? currentCompetitor.status > 0 : false" />
            </MapItem>
            <!-- MAP : end -->
            <MapItem :items="session.ends">
                <MapEnd :animate="animateEndItem" />
            </MapItem>
        </MapCanvas>

        <!-- COMPETITOR -->
        <BaseContainer v-if="currentCompetitor" :class="$style.competitors">
            <CompetitorInfos :competitor="currentCompetitor" />

            <CompetitorTimes
                :class="[status.timeEnabled ? '' : $style.hidden]"
                :currentCompetitor="currentCompetitor"
                :pastCompetitorsFinished="pastCompetitorsFinished"
                :maxtime="session.maxtime"
                :timetobeat="session.timetobeat"
                :currentCompetitorId="session.current_competitor_id"
                :displayFinalDiff="displayFinalDiff"
            />

            <CompetitorFaults :class="[status.timeEnabled ? '' : $style.hidden]" :competitor="currentCompetitor" />

            <CompetitorRank
                :competitor="currentCompetitor"
                :class="[
                    parseInt(currentCompetitor.status) === 1 &&
                    !isNaN(currentCompetitor.time) &&
                    parseFloat(currentCompetitor.time) > 0 &&
                    currentCompetitor.dateFinish
                        ? ''
                        : $style.hidden,
                    status.timeEnabled ? '' : $style.hidden,
                ]"
            />
        </BaseContainer>

        <BaseContainer v-else :class="$style.listCompetitors">
            <ListCompetitor
                v-for="(competitor, key) in allCompetitors"
                :key="`competitor${key}`"
                :competitor="competitor"
                :odd="key % 2 === 0"
                :qualified="
                    parseInt(competitor.status) === 1 &&
                        competitor.time !== null &&
                        ((session.qualified_mode === 'no-faults' && competitor.totalFaults === 0) ||
                            (session.qualified_mode === 'x-first' && key < session.qualified_count))
                "
            />
        </BaseContainer>
        <BaseContainer v-if="session.stream_url && session.stream_id">
            <WebRTCPlayer streamId="492113156734708282693413" />
        </BaseContainer>
    </div>
</template>

<style lang="scss" module>
@import '@design';

.wrapper {
    display: flex;
    flex: 1;
    flex-direction: column;
}
.listCompetitors,
.competitors {
    padding-bottom: 0;
}
.competitors {
    background: linear-gradient(var(--color-background-secondary), var(--color-background-darkest) 50%);
}
.map {
    background: var(--color-background-tertiary);
}
.claps {
    position: relative;
    display: flex;
    flex: 1;
    overflow: hidden;
}
.hidden {
    visibility: hidden;
}
</style>
