const requireModule = require.context('.', false, /\.js$/)
const routes = []

requireModule.keys().forEach((fileName) => {
    if (fileName === './index.js') return

    routes.push(...requireModule(fileName).default)
})

export default routes
