import { mapState, mapGetters, mapActions, mapMutations } from 'vuex'

// Auth
export const authComputed = {
    ...mapState('auth', {
        currentUser: (state) => state.currentUser,
    }),
    ...mapGetters('auth', ['loggedIn']),
}
export const authMethods = mapActions('auth', ['signUp', 'logIn', 'logOut'])

// Form
export const formModule = {
    mutations: mapMutations('form/manage', ['UNSET_ERROR']),
    states: mapState('form/manage', {
        errors: (state) => state.errors,
    }),
}

// LocalStorage
export function getState(key) {
    return JSON.parse(window.localStorage.getItem(key))
}
export function saveState(key, state) {
    window.localStorage.setItem(key, JSON.stringify(state))
}
export function removeState(key) {
    window.localStorage.removeItem(key)
}

// Private Helper
export function clone(value) {
    return JSON.parse(JSON.stringify(value))
}
