<script>
export default {
    props: {
        speeds: {
            type: Array,
            required: true,
        },
    },
}
</script>

<template>
    <div :class="$style.wrapper">
        <!-- SPEEDS -->
        <div :class="$style.speeds">
            <div v-for="(speed, key) in speeds" :key="`speed${key}`" :class="$style.speed">
                <div>{{ speed }} km/h</div>
            </div>
        </div>
    </div>
</template>

<style lang="scss" module>
@import '@design';

.wrapper {
    display: flex;
    align-self: end;
}
.speeds {
    display: flex;
}
.speed {
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: center;
    // text
    font-size: 1em;
    font-weight: 900;

    &:not(:first-child) {
        margin-left: 5px;
        padding-left: 5px;
        border-left: solid var(--color-background) 2px;
    }
}
</style>
