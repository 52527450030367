var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:_vm.$style.wrapper},[_c('MapCanvas',{class:_vm.$style.map,attrs:{"width":_vm.session.canvasWidth,"height":_vm.session.canvasHeight}},[_c('MapItem',{attrs:{"items":_vm.session.assets},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var asset = ref.item;
return [_c('MapAsset',{attrs:{"asset":asset}})]}}])}),_c('MapPath',{attrs:{"paths":_vm.session.paths}}),_c('MapItem',{attrs:{"items":_vm.session.jumps,"mostKnockedJumps":_vm.threeMostKnockedJump},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var jump = ref.item;
return [(jump.jumpType.includes('liverpool'))?_c('MapJumpLiverpool'):_vm._e(),_c('MapJump',{attrs:{"jump":jump}})]}}])}),_c('MapItem',{attrs:{"items":_vm.jumpFaults,"displayText":false},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var jumpFault = ref.item;
return [_c('MapJumpFault',{attrs:{"jump":jumpFault,"mostKnockedJumps":_vm.threeMostKnockedJump}})]}}])}),_c('MapItem',{attrs:{"items":_vm.session.starts}},[_c('MapStart',{attrs:{"animate":_vm.currentCompetitor ? _vm.currentCompetitor.status > 0 : false}})],1),_c('MapItem',{attrs:{"items":_vm.session.ends}},[_c('MapEnd',{attrs:{"animate":_vm.animateEndItem}})],1)],1),(_vm.currentCompetitor)?_c('BaseContainer',{class:_vm.$style.competitors},[_c('CompetitorInfos',{attrs:{"competitor":_vm.currentCompetitor}}),_c('CompetitorTimes',{class:[_vm.status.timeEnabled ? '' : _vm.$style.hidden],attrs:{"currentCompetitor":_vm.currentCompetitor,"pastCompetitorsFinished":_vm.pastCompetitorsFinished,"maxtime":_vm.session.maxtime,"timetobeat":_vm.session.timetobeat,"currentCompetitorId":_vm.session.current_competitor_id,"displayFinalDiff":_vm.displayFinalDiff}}),_c('CompetitorFaults',{class:[_vm.status.timeEnabled ? '' : _vm.$style.hidden],attrs:{"competitor":_vm.currentCompetitor}}),_c('CompetitorRank',{class:[
                parseInt(_vm.currentCompetitor.status) === 1 &&
                !isNaN(_vm.currentCompetitor.time) &&
                parseFloat(_vm.currentCompetitor.time) > 0 &&
                _vm.currentCompetitor.dateFinish
                    ? ''
                    : _vm.$style.hidden,
                _vm.status.timeEnabled ? '' : _vm.$style.hidden ],attrs:{"competitor":_vm.currentCompetitor}})],1):_c('BaseContainer',{class:_vm.$style.listCompetitors},_vm._l((_vm.allCompetitors),function(competitor,key){return _c('ListCompetitor',{key:("competitor" + key),attrs:{"competitor":competitor,"odd":key % 2 === 0,"qualified":parseInt(competitor.status) === 1 &&
                    competitor.time !== null &&
                    ((_vm.session.qualified_mode === 'no-faults' && competitor.totalFaults === 0) ||
                        (_vm.session.qualified_mode === 'x-first' && key < _vm.session.qualified_count))}})}),1),(_vm.session.stream_url && _vm.session.stream_id)?_c('BaseContainer',[_c('WebRTCPlayer',{attrs:{"streamId":"492113156734708282693413"}})],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }