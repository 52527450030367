<script>
export default {
    props: {
        buttons: {
            type: Array,
            required: true,
        },
    },
    data() {
        return {
            active: 0,
        }
    },
    computed: {
        tab() {
            return this.$slots.default[this.active]
        },
    },
}
</script>

<template>
    <div :class="$style.wrapper">
        <div :class="$style.buttonsWrapper">
            <div :class="$style.buttonsEndGradient" />
            <div :class="$style.buttonsInsideWrapper">
                <div :class="$style.buttons">
                    <component
                        :is="active === key ? 'span' : 'a'"
                        v-for="(button, key) in buttons"
                        :key="`tabButton${key}`"
                        :class="[$style.button, active === key ? $style.buttonActive : '']"
                        @click="active = key"
                    >
                        {{ button }}
                    </component>
                </div>
            </div>
        </div>
        <BaseVnode :vnode="tab" />
    </div>
</template>

<style lang="scss" module>
@import '@design';

.wrapper {
    flex: 1;
}
// Buttons
.buttonsWrapper {
    position: relative;
}
.buttonsEndGradient {
    background: linear-gradient(to right, transparent, #fff);
    height: 40px;
    position: absolute;
    pointer-events: none;
    right: 0;
    top: 0;
    width: 24px;
    z-index: 1;
}
.buttonsInsideWrapper {
    position: relative;
    overflow-x: scroll;
    overflow-y: hidden;
    height: 40px;
    box-shadow: $box-shadow;
    margin-left: -3px;
    margin-right: -3px;
    margin-top: 2px;
    border-radius: 0;
    &::-webkit-scrollbar {
        display: none;
    }
}
.buttons {
    background-color: #fff;
    font-size: 12px;
    line-height: 40px;
    height: 40px;
    overflow: hidden;
    justify-content: space-between;
    display: inline-block;
    white-space: nowrap;
    border-top: none;
    padding-left: 8px;
    padding-right: 8px;
}
.button {
    text-transform: uppercase;
    padding: 0 16px;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0.1);
    color: rgba(0, 0, 0, 0.54);
    text-align: center;
    display: inline-block;
}
.buttonActive {
    color: #4285f4;
    border-bottom: 2px solid #4285f4;
    height: 38px;
}
</style>
