<script>
import Layout from '@layouts/main'
// Store
import { mapState, mapGetters, mapMutations, mapActions } from 'vuex'
// Flashs
import FlashContainer from '@components/flash/flash-container'
import FlashNotLive from '@components/flash/flash-not-live'
// Toasts
import SessionIsLive from '@components/toasts/SessionIsLive'
// Vendors
import NoSleep from 'nosleep.js'

import { calculateRunningTime } from '@utils/time'

export default {
    components: {
        SessionIsLive,
        Layout,
        // Flashs
        FlashContainer,
        FlashNotLive,
    },
    data() {
        return {
            noSleep: null,
        }
    },
    computed: {
        ...mapState('session', {
            session: (state) => state.session,
        }),
        ...mapGetters('session', ['currentCompetitor', 'rankCompetitor', 'pastCompetitors']),
        goToPreviousSession() {
            return this.session.parent_id
                ? {
                      name: 'session.map',
                      params: {
                          id: this.session.parent_id,
                      },
                  }
                : null
        },
        goToNextSession() {
            return this.session.children.length
                ? {
                      name: 'session.map',
                      params: {
                          id: this.session.children[0].id,
                      },
                  }
                : null
        },
        rank() {
            return this.rankCompetitor(this.currentCompetitor?.id)
        },
    },
    watch: {
        currentCompetitor: {
            handler: function(newValue, oldValue) {
                console.log('############################### currentCompetitor ###############################')
                if (newValue?.id && newValue?.id !== oldValue?.id) {
                    this.RESET_TIME()
                    this.$refs.nextCompetitor.show()
                } else if (!newValue || !newValue.dateStart) {
                    this.RESET_TIME()
                }

                if (oldValue?.id === newValue?.id && newValue?.status !== oldValue?.status && newValue?.status === 1) {
                    if (parseInt(newValue?.faults) + parseInt(newValue?.timeFaults) === 0) this.$refs.fireworks.show()
                    else this.$refs.finish.show()
                }

                this.resync()
            },
            deep: true,
        },
        rank: {
            handler: function(newValue, oldValue) {
                console.log('rank', this.rank)
                if (this.rank === 1 && this.pastCompetitors?.length > 1) this.$refs.confetti.show()
            },
        },
    },
    mounted() {
        document.addEventListener('visibilitychange', this.revalidateCall, false)
        window.addEventListener('focus', this.revalidateCall, false)
        window.Echo.channel('live').listen('LiveSessionBroadcastResync', () => {
            window.location.reload()
        })

        this.resync()
        // WEBSOCKETS
        window.Echo.channel('live').listen('LiveSessionBroadcast', ({ datas }) => {
            const isSameSessionId =
                datas?.live_session_id && this.session?.id && datas.live_session_id === this.session.id

            if (datas.hasOwnProperty('type') && datas.type === 'toast') {
                if (!isSameSessionId && datas.component === 'SessionIsLive') {
                    this.$toast.dismiss('SessionIsLive')
                    this.$toast(
                        {
                            component: SessionIsLive,
                            props: {
                                url: datas.url,
                                translator: function(key) {
                                    return this.$t(key)
                                }.bind(this),
                            },
                            listeners: {},
                        },
                        {
                            id: 'SessionIsLive',
                            timeout: 300000,
                            icon: false,
                            closeOnClick: false,
                            ...datas.options,
                        }
                    )
                }
            } else if (isSameSessionId && datas.hasOwnProperty('animation')) {
                this.$refs[datas.animation].show()
            } else if (isSameSessionId && datas.hasOwnProperty('needFetchSession')) {
                this.FETCH_SESSION(this.session.id)
            } else if (isSameSessionId && datas.hasOwnProperty('type') && datas.type === 'intermediate') {
                console.log(datas) //TODO here we could display speed and intermediate without reloading the data from the api
            } else if (isSameSessionId && datas.hasOwnProperty('type') && datas.type === 'speed') {
                console.log(datas) //TODO here we could display speed and intermediate without reloading the data from the api
            } else {
                this.EDIT_SESSION(datas)
            }
        })
        this.noSleep = new NoSleep()
        document.addEventListener('click', this.enableNoSleep(), false)

        // MOUNTED
        // if (this.session.ended_at && !this.session.is_live) {
        //     console.log('[mounted] show flash liveStopped')
        //     this.$refs.liveStopped.show()
        // }
    },
    beforeDestroy() {
        if (!this.session.is_live) return
        this.noSleep.disable()
        window.Echo.leaveChannel('live')
    },
    methods: {
        ...mapActions('session', ['FETCH_SESSION', 'STOP_COUNTER', 'START_COUNTER', 'RESET_TIME']),
        ...mapMutations('session', ['EDIT_SESSION']),
        enableNoSleep() {
            document.removeEventListener('click', this.enableNoSleep, false)
            this.noSleep.enable()
        },
        resync() {
            if (!this.currentCompetitor) return
            const providerTime = this.currentCompetitor.time
            if (this.currentCompetitor.is_time_pause) {
                this.STOP_COUNTER(providerTime)
            } else {
                if (this.currentCompetitor.dateStart && !this.currentCompetitor.dateFinish) {
                    const time = calculateRunningTime(this.currentCompetitor)
                    this.START_COUNTER(time)
                } else {
                    this.RESET_TIME()
                }
            }
        },
        revalidateCall() {
            console.log('revalidateCall')
            this.resync()
        },
    },
}
</script>

<template>
    <Layout v-if="session.is_live || session.ended_at">
        <BaseHeader>
            <div
                v-if="goToPreviousSession || goToNextSession"
                :class="[
                    $style.goTo,
                    goToPreviousSession && goToNextSession
                        ? $style.bothButtons
                        : goToPreviousSession
                        ? $style.onlyPreviousButton
                        : $style.onlyNextButton,
                ]"
            >
                <BaseLink v-if="goToPreviousSession" :to="goToPreviousSession" :class="[$style.link, $style.left]">
                    <div :class="$style.previous">
                        <BaseIconBack :class="[$style.arrow, $style.arrowPrevious]" />
                        <div>{{ $t('previous-session') }}</div>
                    </div>
                </BaseLink>
                <BaseLink v-if="goToNextSession" :to="goToNextSession" :class="[$style.link, $style.right]">
                    <div :class="$style.next">
                        <div>{{ $t('next-session') }}</div>
                        <BaseIconBack :class="[$style.arrow, $style.arrowNext]" />
                    </div>
                </BaseLink>
            </div>
            <BaseMenu>
                <BaseMenuLink to="sessions" :active="$route.name.includes('sessions')">
                    {{ $tc('session', 2) }}
                </BaseMenuLink>
                <BaseMenuLink to="session.map" :active="$route.name.includes('session.map')">
                    {{ $t('map') }}
                </BaseMenuLink>
                <BaseMenuLink to="session.statistics" :active="$route.name.includes('session.statistics')">
                    {{ $t('stats') }}
                </BaseMenuLink>
                <BaseMenuLink to="session.results" :active="$route.name.includes('session.results')">
                    {{ $t('results') }}
                </BaseMenuLink>
                <BaseMenuLink
                    v-if="session.is_live"
                    to="session.competitors"
                    :active="$route.name.includes('session.competitors')"
                >
                    {{ $t('next-competitors') }}
                </BaseMenuLink>
                <BaseMenuLink
                    v-else-if="session.video_src"
                    to="session.ghost-riders"
                    :class="$route.name !== 'session.ghost-riders' ? $style.ghostRiders : ''"
                    :active="$route.name.includes('session.ghost-riders')"
                >
                    {{ $t('ghost-riders') }}
                </BaseMenuLink>
                <BaseMenuLink v-if="session.external_link" :href="session.external_link">
                    {{ $t('live-stream') }}
                </BaseMenuLink>
            </BaseMenu>
        </BaseHeader>

        <!-- MAIN -->
        <main :class="$style.main">
            <RouterView :session="session" />
        </main>

        <BaseFooter />

        <!-- FLASHS -->
        <FlashContainer ref="nextCompetitor" name="nextCompetitor" :competitor="currentCompetitor" />
        <FlashContainer ref="liveStopped" name="liveStopped" :duration="6000" :session="session" />
        <FlashContainer ref="buyMove" name="buyMove" />
        <FlashContainer ref="confetti" name="confetti" :duration="6000" />
        <FlashContainer ref="fireworks" name="fireworks" :duration="5500" />
        <FlashContainer ref="finish" name="finish" :duration="1800" />
        <FlashContainer ref="bell" name="bell" :duration="1500" />
    </Layout>
    <FlashNotLive v-else :session="session" />
</template>

<style lang="scss" module>
@import '@design';

.main {
    display: flex;
    flex: 1;
    padding-bottom: 30px;
}
.goTo {
    display: flex;
    margin-top: -5px;
    margin-bottom: 5px;

    &.bothButtons {
        justify-content: space-between;
    }
    &.onlyPreviousButton {
        justify-content: flex-start;
    }
    &.onlyNextButton {
        justify-content: flex-end;
    }

    .link {
        font-size: 0.6rem;
        color: var(--color-secondary);
        background-color: var(--color-link);
        padding: 4px 7px;
        border-radius: 5px;
        .next,
        .previous {
            display: flex;
            height: 10px;

            .arrow {
                width: 5px;
                color: var(--color-secondary);

                &.arrowPrevious {
                    margin-right: 5px;
                }
                &.arrowNext {
                    margin-left: 5px;
                    transform: rotate(180deg);
                }
            }
        }

        &.left {
            margin-left: 10px;
        }

        &.right {
            margin-right: 10px;
        }
    }
}

/* Animation ghost riders */
.ghostRiders {
    position: relative;
    overflow: hidden;
}

.ghostRiders:before {
    position: absolute;
    content: '';
    display: inline-block;
    top: -180px;
    left: 150px;
    width: 30px;
    height: 100%;
    background-color: var(--color-secondary);
    animation: shiny 5s ease-in-out infinite;
}

@-webkit-keyframes shiny {
    0% {
        -webkit-transform: scale(0) rotate(45deg);
        opacity: 0;
    }
    80% {
        -webkit-transform: scale(0) rotate(45deg);
        opacity: 0.5;
    }
    81% {
        -webkit-transform: scale(4) rotate(45deg);
        opacity: 1;
    }
    100% {
        -webkit-transform: scale(50) rotate(45deg);
        opacity: 0;
    }
}
</style>
