<script>
import design from '@design'

export default {
    props: {
        jump: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            color: {
                white: design['colorMapJumpWhite'],
                extremities: design['colorMapJumpExtremities'],
                stroke: design['colorMapJumpStroke'],
                river: design['colorMapJumpRiver'],
            },
        }
    },
    methods: {
        setStyle() {
            requestAnimationFrame(() => {
                this.$el.style.transform = `translate(-50%, -50%) scale(0.2)`
            })
        },
    },
}
</script>

<template>
    <g :style="setStyle()" :class="[$style.jump]">
        <!-- Vertical -->
        <g v-if="jump.jumpType === 'vertical' || jump.jumpType === 'vertical-liverpool'">
            <path :fill="color.extremities" d="M22.03 0h1.95v7.92h-1.95zM0 0h1.95v7.92H0z" />
            <path d="M1.64 2.87h20.65v2.19H1.64z" />
            <path
                stroke-width=".25"
                :stroke="color.stroke"
                stroke-linecap="round"
                stroke-linejoin="round"
                fill="none"
                d="M1.64 2.87h20.65v2.19H1.64z"
            />
            <path :fill="color.white" d="M14.42 2.87h3.93v2.19h-3.93zM5.57 2.87H9.5v2.19H5.57z" />
        </g>
        <!-- Oxer -->
        <g v-if="jump.jumpType === 'oxer' || jump.jumpType === 'oxer-liverpool'">
            <!-- 1st extremitie -->
            <path :fill="color.extremities" d="M22.03 0h1.95v5.83h-1.95zM0 0h1.95v5.83H0z" />
            <!-- 1st bar -->
            <path d="M1.64 1.82h20.65v2.19H1.64z" />
            <path
                stroke-width=".25"
                :stroke="color.stroke"
                stroke-linecap="round"
                stroke-linejoin="round"
                fill="none"
                d="M1.64 1.82h20.65v2.19H1.64z"
            />
            <path :fill="color.white" d="M14.42 1.82h3.93v2.19h-3.93zM5.57 1.82H9.5v2.19H5.57z" />
            <!-- 2nd extremitie -->
            <path :fill="color.extremities" d="M22.06 6.6h1.95v5.92h-1.95zM.02 6.6h1.95v5.92H.02z" />
            <!-- 2nd bar -->
            <path d="M1.66 8.47h20.65v2.19H1.66z" />
            <path
                stroke-width=".25"
                :stroke="color.stroke"
                stroke-linecap="round"
                stroke-linejoin="round"
                fill="none"
                d="M1.66 8.47h20.65v2.19H1.66z"
            />
            <path :fill="color.white" d="M14.44 8.47h3.93v2.19h-3.93zM5.59 8.47h3.93v2.19H5.59z" />
        </g>
        <!-- Triple -->
        <g v-if="jump.jumpType === 'triple' || jump.jumpType === 'triple-liverpool'">
            <path :fill="color.extremities" d="M0 0h1.95v13.19H0zM22.05 0H24v13.19h-1.95z" />
            <path d="M1.64 1.94h20.65v2.19H1.64z" />
            <path
                stroke-width=".25"
                :stroke="color.stroke"
                stroke-linecap="round"
                stroke-linejoin="round"
                fill="none"
                d="M1.64 1.94h20.65v2.19H1.64z"
            />
            <path :fill="color.white" d="M14.42 1.94h3.93v2.19h-3.93zM5.57 1.94H9.5v2.19H5.57z" />
            <path d="M1.64 5.64h20.65v2.19H1.64z" />
            <path
                stroke-width=".25"
                :stroke="color.stroke"
                stroke-linecap="round"
                stroke-linejoin="round"
                fill="none"
                d="M1.64 5.64h20.65v2.19H1.64z"
            /><path :fill="color.white" d="M14.42 5.64h3.93v2.19h-3.93zM5.57 5.64H9.5v2.19H5.57z" />
            <path d="M1.64 9.24h20.65v2.19H1.64z" />
            <path
                stroke-width=".25"
                :stroke="color.stroke"
                stroke-linecap="round"
                stroke-linejoin="round"
                fill="none"
                d="M1.64 9.24h20.65v2.19H1.64z"
            /><path :fill="color.white" d="M14.42 9.24h3.93v2.19h-3.93zM5.57 9.24H9.5v2.19H5.57z" />
        </g>
        <!-- Wall -->
        <g v-if="jump.jumpType === 'wall'">
            <path d="M.00.10h22.88v2.41H.38z" />
            <path
                stroke-width=".75"
                :stroke="color.stroke"
                stroke-linecap="round"
                stroke-linejoin="round"
                fill="none"
                d="M.00.10h22.88v2.41H.38z"
            />
            <path
                :fill="color.white"
                d="M2.98.35h.27v2.41h-.27zM6.43.35h.27v2.41h-.27zM9.67.35h.27v2.41h-.27zM13.23.35h.27v2.41h-.27zM16.66.35h.27v2.41h-.27zM20.11.35h.27v2.41h-.27z"
            />
        </g>
        <!-- River -->
        <g v-if="jump.jumpType === 'river'">
            <path :fill="color.river" d="M.00.10h22.88v12.15H.38z" />
            <path
                stroke-width=".75"
                :stroke="color.river"
                stroke-linecap="round"
                stroke-linejoin="round"
                :fill="color.river"
                d="M.00.10h22.88v12.15H.38z"
            />
        </g>
        <!-- Plank -->
        <g v-if="jump.jumpType === 'plank' || jump.jumpType === 'plank-liverpool'">
            <path
                d="
                M 0 0
                h 20.88 v 2.41
                H 0 z"
            />
            <path
                fill="none"
                :stroke="color.stroke"
                stroke-width=".75"
                stroke-linecap="round"
                stroke-linejoin="round"
                d="
                M 0 0
                h 20.88 v 2.41
                H .0 z"
            />
        </g>
    </g>
</template>

<style lang="scss" module>
@import '@design';

.river,
.liverpool {
    fill: var(--color-blue);
    stroke: var(--color-blue);
}
.liverpool {
    transform: translate(0, 4.5%);
}
.jump {
    transform: translate(-50%, -50%);
    transform-box: fill-box;
    transform-origin: center;
}
.selected {
    fill: var(--color-red);
    stroke: transparent;
}
</style>
