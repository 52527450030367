import Vue from 'vue'
import { i18n, loadTranslations } from '@utils/i18n'
import App from './app'
import router from '@router'
import store from '@state/store'
import './vendor'
import '@components'
import '@components/directives'
import '@components/filters'
import 'intersection-observer'
import VueGtag from 'vue-gtag'
import Particles from 'particles.vue'
import Toast from 'vue-toastification'
import VueTippy, { TippyComponent } from 'vue-tippy'

Vue.use(VueGtag, {
    config: { id: process.env.VUE_APP_GTAG },
})
Vue.use(Particles)
Vue.use(Toast, {})
Vue.use(VueTippy)
Vue.component('tippy', TippyComponent)

Vue.config.productionTip = process.env.NODE_ENV === 'production'

loadTranslations(localStorage.locale ? localStorage.locale : 'en')

Vue.prototype.$locale = {
    change(lang) {
        // ? We could save lang for the user
        // let id = store.state.user.id

        // if (id != '' && lang != null && this.current() !== lang) {
        //     // request to save lang for user
        // }

        loadTranslations(lang)
    },
    current() {
        return Vue.config.lang
    },
}

switch (window.location.hostname) {
    // case 'localhost':
    case 'rolexgrandslam.alogo.io':
    case 'dev.rolexgrandslam.alogo.io':
        Vue.prototype.$theme = 'rgs'
        Vue.prototype.$colors = ['#006038', '#4E9446', '#A0AB69', '#a37e2c', '#D3BA3F', '#8d9dca', '#e78ac3', '#202124']
        Vue.prototype.$ignoreConcoursLogo = true
        Vue.prototype.$logo = 'rgs'
        Vue.prototype.$logoUrl = 'https://www.rolexgrandslam.com/'
        Vue.prototype.$name = 'Rolex Grand Slam'
        window.document.title = 'Alogo Live'
        break
    // case 'localhost':
    case 'test.live.alogo.io':
        Vue.prototype.$theme = 'test'
        Vue.prototype.$colors = ['#57a5e0', '#197ABF', '#4E9446', '#AECB53', '#D0BCA3', '#F5EDE2', '#e78ac3', '#202124']
        Vue.prototype.$ignoreConcoursLogo = false
        Vue.prototype.$logo = 'alogo'
        Vue.prototype.$logoUrl = 'https://alogo.io/products/'
        Vue.prototype.$name = 'Alogo Live'
        window.document.title = 'Alogo Live'
        break
    default:
        Vue.prototype.$theme = 'alogo'
        Vue.prototype.$colors = ['#57a5e0', '#197ABF', '#4E9446', '#AECB53', '#D0BCA3', '#F5EDE2', '#e78ac3', '#202124']
        Vue.prototype.$ignoreConcoursLogo = false
        Vue.prototype.$logo = 'alogo'
        Vue.prototype.$logoUrl = 'https://alogo.io/products/'
        Vue.prototype.$name = 'Alogo Live'
        window.document.title = 'Alogo Live'
}

new Vue({
    router,
    store,
    i18n,
    render: (h) => h(App),
}).$mount('#app')
