<script>
import kebabCase from 'lodash/kebabCase'
import { sleep } from '@utils/time'

export default {
    props: {
        name: {
            type: String,
            required: true,
        },
        duration: {
            type: Number,
            default: 5500,
        },
    },
    data() {
        return {
            isShow: false,
        }
    },
    computed: {
        flashComponent() {
            const name = kebabCase(this.name)
            let flash = import(`@components/flash/flash-${name}.vue`)
            return () => flash
        },
    },
    methods: {
        async show() {
            this.isShow = true
            if (this.duration) {
                await sleep(this.duration)
                this.hide()
            }
        },
        hide() {
            this.isShow = false
        },
    },
}
</script>

<template>
    <div>
        <transition
            :enter-class="$style.enter"
            :enter-active-class="$style.enterActive"
            :leave-active-class="$style.leaveActive"
            :leave-to-class="$style.leaveTo"
        >
            <div v-if="isShow" :class="[$style.wrapper, $style.overlay]" />
        </transition>
        <transition
            :enter-class="$style.enter"
            :enter-active-class="$style.enterActive"
            :leave-active-class="$style.leaveActive"
            :leave-to-class="$style.leaveTo"
        >
            <div v-if="isShow" :class="$style.wrapper">
                <component :is="flashComponent" v-bind="$attrs" @close="hide" />
            </div>
        </transition>
    </div>
</template>

<style lang="scss" module>
@import '@design';

.wrapper {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 9999;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    transform: translate3d(0, 0, 0);
}
.overlay {
    background: rgba(black, 0.85);
}

// Transition
.enterActive,
.leaveActive {
    transition: all 0.3s ease;
}
.enter,
.leaveTo {
    opacity: 0;
}
</style>
