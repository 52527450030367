export const states = {
    // Store to Database
    database: {
        live_competition_id: 0,
        type: '',
        name: '',
        maxtime: 0,
        timetobeat: 0,
        length: 0,
        begin_at: '',
        round: 1, //default Manche 1
        parent_id: null,
        jumps: [],
        cells: [],
        paths: [],
        assets: [],
        starts: [],
        ends: [],
        competitors: [],
        canvasWidth: 100,
        canvasHeight: 60,
    },
    // Need for Editor
    editor: {
        selected: [],
        windowPosition: null,
        choiceMenu: null,
        planPreview: null,
    },
}

export const stateJump = {
    type: 'jump',
    name: null,
    x: 0,
    y: 0,
    r: 0,
    jumpType: 'vertical',
}

export const stateFault = {
    status: [],
}

export const statePath = {
    name: null,
    x: 0,
    y: 0,
}

export const stateAsset = {
    name: '',
    type: '',
    x: 0,
    y: 0,
    r: 0,
}

export const stateStart = {
    name: '',
    type: '',
    x: 0,
    y: 0,
    r: 0,
}

export const stateEnd = {
    name: '',
    type: '',
    x: 0,
    y: 0,
    r: 0,
}

export const stateCell = {
    channel: 'C',
    jumpId: null,
    distance: null,
    type: null,
}

export const stateCompetitor = {
    no_fei: null,
    cnr: null,
    name: null,
    startNumber: null,
    country: null,
    photo: null,
    horseName: null,
    status: 0,
    jumps: [],
    faults: null,
    timeFaults: null,
    totalFaults: null,
    //bellow same as stateCompetitorForReset
    speed: [],
    dateStart: null,
    datesPause: [],
    dateFinish: null,
    time: 0,
    algeTimeStart: null,
    algeTimeFinish: null,
    status: 0,
    is_time_pause: false,
    pulsations: [],
    timeIntermediate: [],
}

export const stateCompetitorForReset = {
    speed: [],
    dateStart: null,
    datesPause: [],
    dateFinish: null,
    time: 0,
    algeTimeStart: null,
    algeTimeFinish: null,
    status: 0,
    is_time_pause: false,
    pulsations: [],
    timeIntermediate: [],
}

export const rounds = [
    {
        value: 1,
        text: 'Manche 1',
        cumulateFaults: false,
    },
    {
        value: 2,
        text: 'Manche 2 first round time ranking',
        cumulateFaults: true,
        includePreviousTimeRanking: true,
    },
    {
        value: 4,
        text: 'Manche 2 second round time ranking',
        cumulateFaults: true,
        includePreviousTimeRanking: false,
    },
    {
        value: 3,
        text: 'Jump off',
        cumulateFaults: false,
    },
]
