<script>
export default {
    props: {
        center: {
            type: Boolean,
            default: false,
        },
    },
}
</script>

<template functional>
    <div
        :class="[
            $style.headline,
            $slots.action ? $style.headlineAction : '',
            props.center ? $style.headlineCenter : '',
        ]"
    >
        <div :class="$style.titles">
            <h2 :class="$style.title">
                <slot />
            </h2>
            <p v-if="$slots.subtitle" :class="$style.subtitle">
                <slot name="subtitle" />
            </p>
        </div>

        <slot name="action" />
    </div>
</template>

<style lang="scss" module>
@import '@design';

.headline {
    margin-top: 24px;
    margin-bottom: 46px;

    &.headlineAction {
        display: flex;
        align-items: flex-end;
        justify-content: space-between;
    }
    &.headlineCenter {
        text-align: center;
    }
}
.titles {
    align-self: flex-start;
    .title {
        font-family: var(--font-family)-heading;
        font-size: 1.6em;
        font-weight: 500;
    }
    .subtitle {
        margin-top: 10px;
        font-size: 1.1em;
        font-weight: 100;
        color: var(--color-text-secondary);
    }
}
</style>
