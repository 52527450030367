<script>
import { mapState, mapMutations } from 'vuex'

export default {
    props: {
        name: {
            type: String,
            required: true,
        },
        title: {
            type: String,
            required: true,
        },
        tag: {
            type: String,
            default: 'div',
        },
    },
    computed: {
        ...mapState('session/editor', {
            toolSelected: (state) => state.toolSelected,
        }),
    },
    methods: {
        ...mapMutations('session/editor', ['SET_TOOL_SELECTED']),
        onClick() {
            if (this.toolSelected === this.name) {
                this.SET_TOOL_SELECTED(null)
            } else {
                this.SET_TOOL_SELECTED(this.name)
            }
        },
    },
}
</script>

<template>
    <component :is="tag" :class="[$style.tool, toolSelected === name ? $style.selected : '']" @click="onClick">
        <BaseIcon :name="name" />
        <span>{{ title }}</span>
    </component>
</template>

<style lang="scss" module>
@import '@design';

.tool {
    @include unselectable;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 45px;
    cursor: pointer;
    opacity: 0.4;
    span {
        display: block;
        margin-top: 2px;
        font-size: 8px;
        font-weight: 600;
        color: white;
    }
    svg {
        width: 20px;
        height: 20px;
        fill: white;
    }
    &.selected,
    &:hover {
        opacity: 1;
        svg {
            fill: #57a5e0;
        }
        span {
            color: #57a5e0;
        }
    }
    &:first-child {
        margin-top: 5px;
    }
    &:last-child {
        margin-bottom: 5px;
    }
}
</style>
