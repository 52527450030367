<script>
import IconBack from '@assets/icons/back-arrow.svg'

export default {
    components: {
        IconBack,
    },
}
</script>

<template>
    <IconBack />
</template>
