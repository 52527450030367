<script>
export default {
    props: {
        name: {
            type: String,
            required: true,
        },
        color: {
            type: String,
            default: '',
        },
    },
    computed: {
        icon() {
            let icon = import(`@assets/icons/${this.name}.svg`)
            return () => icon
        },
        colorClass() {
            return this.color ? this.$style[`${this.color}Color`] : ''
        },
    },
}
</script>

<template>
    <component :is="icon" :class="[$style.icon, colorClass]" />
</template>

<style lang="scss" module>
@import '@design';

.icon {
    width: 24px;
    height: 24px;
}
.purpleColor {
    fill: var(--color-purple);
}
.redColor {
    fill: var(--color-red);
}
.orangeColor {
    fill: var(--color-orange);
}
.whiteColor {
    fill: white;
}
.greyColor {
    fill: #9aa0a6;
}
</style>
