<script>
import IconImage from '@assets/icons/image.svg'

export default {
    components: {
        IconImage,
    },
    data() {
        return {
            imageError: false,
        }
    },
    props: {
        image: {
            validator: (prop) => typeof prop === 'string' || prop === null,
            required: true,
        },
        isLogo: {
            type: Boolean,
            default: false,
        },
    },
}
</script>

<template>
    <div v-if="!image || imageError" :class="[$style.svg, isLogo ? $style.bigSvg : '']">
        <IconImage />
    </div>
    <img v-else :src="image" :class="[$style.image, isLogo ? $style.bigImage : '']" @error="imageError = true" />
</template>

<style lang="scss" module>
@import '@design';

.image,
.svg {
    width: 48px;
    height: 48px;
    margin-right: 1rem;
    border: 2px solid var(--color);
    border-radius: $border-radius-round;
}
.bigImage,
.bigSvg {
    width: 70px;
    height: 70px;
}
.image {
    display: inline-flex;
    align-items: center;
    justify-content: center;
}
.svg {
    display: flex;
    align-items: center;
    justify-content: center;
    svg {
        width: 26px;
        height: 26px;
        fill: var(--color-background-image-light);
    }
}
</style>
