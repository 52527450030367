<script>
import IconImage from '@assets/icons/image.svg'
import countryISOmapping from '@utils/countryISOmapping'
import { constantsCompetitorStatus } from '@constants/competitor'
import { containsEmoji } from '@utils/text'

export default {
    components: {
        IconImage,
    },
    data() {
        return {
            imageError: false,
        }
    },
    props: {
        competitor: {
            type: Object,
            required: true,
        },
    },
    computed: {
        countryCode() {
            return this.competitor.country ? countryISOmapping(this.competitor.country) : ''
        },
        status() {
            return constantsCompetitorStatus.find((status) => status.value === this.competitor.status)
        },
    },
    methods: {
        containsEmoji,
    },
    watch: {
        competitor: function(newCompetitor, oldCompetitor) {
            if (newCompetitor?.id !== oldCompetitor?.id) this.imageError = false
        },
    },
}
</script>

<template>
    <div :class="$style.wrapper">
        <!-- COMPETITOR -->
        <div :class="$style.competitor">
            <!-- COMPETITOR: photo -->
            <div v-if="!competitor.photo || imageError" :class="$style.svg">
                <IconImage />
            </div>
            <img v-else :src="competitor.photo" :class="$style.photo" @error="imageError = true" />

            <!-- COMPETITOR: names -->
            <div :class="$style.names">
                <div :class="$style.name">
                    <div
                        v-if="containsEmoji(competitor.name)"
                        :content="`${$t('live-contenders-explanation')} <br> ${$t('bonus-contenders-explanation')}`"
                        v-tippy="{ placement: 'top-start', arrow: false, animation: 'scale', maxWidth: 450 }"
                    >
                        {{ competitor.name }}
                    </div>
                    <div v-else>
                        {{ competitor.name }}
                    </div>
                </div>
                <div :class="$style.nameHorse">
                    {{ competitor.horseName }}
                </div>
            </div>
            <!-- COMPETITOR: details -->
            <div :class="$style.details">
                <!-- country -->
                <div :class="$style.detail">
                    <div :class="$style.detailTitle"> {{ $t('country') }} </div>
                    <div :class="$style.country">
                        <div :class="$style.detailContent">
                            {{ competitor.country }}
                        </div>
                        <div :class="$style.flag">
                            <img
                                :src="'https://flagcdn.com/h20/' + countryCode.toLowerCase() + '.png'"
                                onerror="this.style.display='none'"
                            />
                        </div>
                    </div>
                </div>
                <!-- start number -->
                <div :class="$style.detail">
                    <div :class="$style.detailTitle"> {{ $t('number') }} </div>
                    <div :class="$style.detailContent">
                        {{ competitor.startNumber }}
                    </div>
                </div>
                <!-- CNR -->
                <!-- <div :class="$style.detail">
                    <div :class="$style.detailTitle"> {{ $t('cnr') }} </div>
                    <div :class="$style.detailContent">
                        {{ competitor.cnr }}
                    </div>
                </div> -->
            </div>
        </div>
        <div v-if="parseInt(status.value) !== 1" :class="$style.rightStatus">
            {{ status.text }}
        </div>
    </div>
</template>

<style lang="scss" module>
@import '@design';

.wrapper {
    padding-bottom: 20px;
}

//
// COMPETITOR
//
.competitor {
    display: flex;
    align-items: center;
}
// COMPETITOR: photo
.photo,
.svg {
    width: 60px;
    height: 60px;
    border: 3px solid var(--color);
    border-radius: $border-radius-round;
}
.svg {
    display: flex;
    align-items: center;
    justify-content: center;
    svg {
        width: 26px;
        height: 26px;
        fill: var(--color-secondary-lighter);
    }
}
// COMPETITOR: names
.names {
    flex: 1;
    margin-left: 10px;
    color: var(--color-lightest-gray);
}
.name {
    font-size: 1.2em;
    font-weight: 800;
}
.nameHorse {
    margin-top: 3px;
    font-size: 0.9em;
    opacity: 0.85;
}
// COMPETITOR: details
.details {
    display: flex;
    margin-left: 15px;
}
.detail {
    padding: 6px 10px 4px 10px;
    background: var(--color-background-tertiary);
    border-radius: 2px;
    &:not(:first-child) {
        margin-left: 5px;
    }
}
.detailTitle {
    margin-bottom: 3px;
    font-size: 0.6em;
    font-weight: 600;
    text-transform: uppercase;
    opacity: 0.8;
}
.detailContent {
    font-size: 1.2em;
    font-weight: 800;
}
.country {
    display: flex;
}
.flag {
    margin-left: 5px;
}
.rightStatus {
    display: inline-flex;
    padding: 5px;
    font-size: 12px;
    color: var(--color);
    border: 1px solid var(--color);
    border-radius: $border-radius;
    white-space: nowrap;
    float: right;
}
</style>
