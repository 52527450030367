<script>
import { mapGetters } from 'vuex'

export default {
    props: {
        competitor: {
            type: Object,
            required: true,
        },
    },
    computed: {
        ...mapGetters('session', ['rankCompetitor']),
        rank() {
            return this.rankCompetitor(this.competitor.id)
        },
    },
}
</script>

<template>
    <div :class="[$style.wrapper, rank ? '' : $style.hidden]">
        <!-- MAIN -->
        <div :class="$style.main">
            <div :class="$style.mainTitle"> {{ $tc('rank') }} </div>
            <div :class="$style.mainContent"> {{ rank }}</div>
        </div>
    </div>
</template>

<style lang="scss" module>
@import '@design';

.wrapper {
    display: flex;
}

// MAIN
.main {
    flex: 1;
}
.mainTitle {
    font-size: 0.9em;
    font-weight: 600;
    text-transform: uppercase;
    opacity: 0.8;
}
.mainContent {
    font-size: 2em;
    font-weight: 800;
}
.hidden {
    visibility: hidden;
}
</style>
