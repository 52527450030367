<script>
import Layout from '@layouts/main'
// import design from '@design'

export default {
    components: { Layout },
    data() {
        return {}
    },
}
</script>

<template>
    <Layout>
        <BaseHeader />
        <main :class="$style.main">
            <BaseContainer>
                <BaseEmpty> {{ $t('coming-soon').toUpperCase() }} </BaseEmpty>
            </BaseContainer>
        </main>

        <BaseFooter />
    </Layout>
</template>

<style lang="scss" module>
@import '@design';

.main {
    display: flex;
    flex: 1;
}
</style>
