<script>
export default {
    data() {
        return {
            open: false,
        }
    },
    methods: {
        show(message) {
            if (message) {
                this.$slots.default = [this.$createElement('div', message)]
            }
            this.open = true
            this.$store.dispatch('modal/fixBackgroundScroll', {
                open: true,
            })
        },
        close() {
            this.open = false
            this.$store.dispatch('modal/fixBackgroundScroll', {
                open: false,
            })
            this.$emit('close')
        },
    },
}
</script>

<template>
    <transition
        :move-class="$style.transition"
        :enter-class="$style.transitionEnter"
        :leave-active-class="$style.transitionLeaveActive"
    >
        <div v-if="open" :class="$style.mask">
            <div :class="$style.wrapper">
                <div v-if="$slots.header" :class="$style.header">
                    <slot name="header" />
                </div>
                <div :class="$style.body">
                    <slot />
                </div>
                <div :class="$style.buttons">
                    <slot name="button" />
                    <BaseButton @click.native="close">
                        <slot name="close">Ok</slot>
                    </BaseButton>
                </div>
                <div v-if="$slots.links" :class="$style.links">
                    <slot name="links" />
                </div>
            </div>
        </div>
    </transition>
</template>

<style lang="scss" module>
@import '@design';

.mask {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 9998;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    background-color: white;
    transition: opacity 0.3s ease;
}
.wrapper {
    width: 500px;
    padding: 20px 30px;
    margin: 0 auto;
    text-align: center;
    transition: all 0.3s ease;
}
.header {
    display: block;
    margin-bottom: 20px;
    font-size: 23px;
    font-weight: 800;
}
.body {
    font-size: 18px;
    line-height: 1.4;
}
.links {
    margin-top: 50px;
    font-size: 13px;
    a {
        display: block;
        line-height: 1.7;
        color: var(--color-link);
        opacity: 0.75;
    }
}

// button
.buttons {
    display: flex;
    justify-content: center;
    width: 100%;
    margin-top: 40px;
}

// transition
.transitionEnter {
    opacity: 0;
}
.transitionLeaveActive {
    opacity: 0;
}
.transitionEnter,
.transitionLeaveActive {
    .wrapper {
        transform: scale(1.1);
    }
}
</style>
