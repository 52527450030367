<script>
import IconBack from '@assets/icons/back-arrow.svg'

export default {
    components: {
        IconBack,
    },
    props: {
        collapsable: {
            type: Boolean,
            default: true,
        },
        collapsed: {
            type: Boolean,
            default: false,
        },
        title: {
            type: String,
            default: null,
        },
        subtitle: {
            type: String,
            default: null,
        },
    },
    data() {
        return {
            isCollapsed: this.collapsed,
        }
    },
}
</script>

<template>
    <BaseCard>
        <div v-if="title" :class="$style.header" @click="isCollapsed = !isCollapsed">
            <div>
                <h4 :class="$style.title">
                    <span>{{ title }} </span>
                    <IconBack
                        v-if="collapsable"
                        :class="[$style.chevron, isCollapsed ? $style.closed : $style.opened]"
                    />
                </h4>
                <p v-if="subtitle" :class="$style.subtitle">
                    {{ subtitle }}
                </p>
            </div>
            <div v-if="$slots.actions" :class="$style.actions" @click.stop>
                <slot name="actions" />
            </div>
        </div>

        <table v-if="!isCollapsed" :class="$style.table">
            <slot />
        </table>
    </BaseCard>
</template>

<style lang="scss" module>
@import '@design';

.header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0.5rem;
    border-bottom: 1px solid rgba(white, 0.1);
    border-radius: $border-radius $border-radius 0 0;
}

// TABLE
.table {
    width: 100%;
    background-color: transparent;
}

.title {
    padding: 10px;
    display: flex;
    cursor: pointer;
}

.subtitle {
    margin-top: 8px;
}

.chevron {
    width: 6px;
    margin-left: 10px;

    &.opened {
        transform: rotate(90deg);
    }

    &.closed {
        transform: rotate(-90deg);
    }
}
</style>
