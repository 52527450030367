<script>
import { sleep } from '@utils/time'

export default {
    name: 'BaseTransition',
    methods: {
        async enter(element) {
            const width = getComputedStyle(element).width

            element.style.width = width
            element.style.position = 'absolute'
            element.style.visibility = 'hidden'
            element.style.height = 'auto'

            const height = getComputedStyle(element).height

            element.style.width = null
            element.style.position = null
            element.style.visibility = null
            element.style.height = 0

            await sleep(0)
            element.style.height = height
        },
        afterEnter(element) {
            element.style.height = 'auto'
        },
        async leave(element) {
            const height = getComputedStyle(element).height

            element.style.height = height

            await sleep(0)
            element.style.height = 0
        },
    },
}
</script>

<template>
    <transition name="expand" @enter="enter" @after-enter="afterEnter" @leave="leave">
        <slot />
    </transition>
</template>

<style lang="scss">
.expand-enter-active,
.expand-leave-active {
    overflow: hidden;
    transition: height 0.3s cubic-bezier(0.3, 0, 0, 1);
}

.expand-enter,
.expand-leave-to {
    height: 0;
}
</style>

<style scoped>
* {
    will-change: height;
    transform: translateZ(0);
    backface-visibility: hidden;
    perspective: 1000px;
}
</style>
