<script>
import design from '@design'

export default {
    props: {
        items: {
            type: Array,
            required: true,
        },
        displayText: {
            type: Boolean,
            default: true,
        },
        mostKnockedJumps: {
            type: Array,
            default: () => [],
        },
    },
    data() {
        return {
            mostKnockedGradient: {
                start: design['colorMapJumpFault'],
                stop: design['colorBackgroundRadient'],
            },
        }
    },
}
</script>

<template>
    <g v-if="items.length">
        <svg :id="item.id" v-for="item in items" :key="item.id" :x="item.x" :y="item.y" :class="$style.wrapper">
            <!-- Item -->
            <g :style="{ transform: `rotate(${item.r}deg)` }">
                <slot :item="item" />
            </g>

            <!-- Most knocked jumps -->
            <template v-if="mostKnockedJumps.map((knockedJump) => knockedJump.name).includes(item.name)">
                <defs>
                    <radialGradient id="gradient-most-knocked">
                        <stop offset="0%" :stop-color="mostKnockedGradient.start" />
                        <stop offset="100%" :stop-color="mostKnockedGradient.stop" />
                    </radialGradient>
                </defs>
                <circle :class="$style.mostKnockedArea" :r="4" />

                <!-- Percent -->
                <!-- <text
                    :class="
                        mostKnockedJumps.map((knockedJump) => knockedJump.name).includes(item.name)
                            ? [$style.text, $style.mostKnockedJump, $style.percent]
                            : $style.text
                    "
                    :dx="'-4%'"
                    :dy="'7.5%'"
                >
                    ({{ mostKnockedJumps.find((knockedJump) => knockedJump.name === item.name).knockedPercent }}%)
                </text> -->
            </template>

            <!-- Jump name -->
            <template v-if="item.name && item.type === 'jump' && displayText">
                <rect width="3.5" height="2" x="-5.7%" y="2.5%" :class="$style.textBackground" />

                <text
                    :class="
                        mostKnockedJumps.map((knockedJump) => knockedJump.name).includes(item.name)
                            ? [$style.text, $style.mostKnockedJump]
                            : $style.text
                    "
                    dx="-4%"
                    dy="5%"
                >
                    {{ item.name }}
                </text>
            </template>
        </svg>
    </g>
</template>

<style lang="scss" module>
@import '@design';

.wrapper {
    @include unselectable;

    overflow: visible;
    cursor: pointer;
}
.text {
    @include unselectable;

    font-size: 1.5px;
    font-weight: 400;
    text-anchor: middle;
    fill: #f0f0f0;
}
.mostKnockedJump {
    &.percent {
        font-size: 1.2px;
    }
}
.mostKnockedArea {
    fill: url(#gradient-most-knocked);
    opacity: 0.5;
}
.text-background {
    fill: var(--color-background-secondary);
    stroke: var(--color);
    stroke-width: 0.1px;
    opacity: 0.8;
}
</style>
