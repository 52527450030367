<script>
import { mapActions } from 'vuex'

export default {
    props: {
        currentCompetitor: {
            type: Object,
            required: true,
        },
        sessionJumps: {
            type: Array,
            required: true,
        },
        currentJumpId: {
            type: String,
            default: null,
        },
    },
    computed: {
        jumps() {
            return this.sessionJumps.map((sessionJump) => {
                const competitorJump = this.currentCompetitor.jumps.find(
                    (competitorJump) => competitorJump.id === sessionJump.id
                )
                return {
                    id: sessionJump.id,
                    name: sessionJump.name,
                    type: sessionJump.jumpType,
                    status: competitorJump.status,
                }
            })
        },
    },
    methods: {
        ...mapActions('session', ['UPDATE_CURRENT_JUMP_ID']),
        hasFaults(status) {
            return status.some((statu) => statu > 0)
        },
    },
}
</script>

<template>
    <div :class="$style.wrapper">
        <!--
      TITLE
    -->
        <div :class="$style.title"> {{ $tc('jumps', 2) }} </div>
        <!--
      JUMPS
    -->
        <div :class="$style.jumps">
            <div
                v-for="(jump, key) in jumps"
                :key="`jump${key}`"
                :class="[
                    $style.jump,
                    jump.id === currentJumpId ? $style.jumpActive : '',
                    jump.status.length && !hasFaults(jump.status) ? $style.jumpStatusGood : '',
                    jump.status.length && hasFaults(jump.status) ? $style.jumpStatusBad : '',
                ]"
                @contextmenu.prevent.default="UPDATE_CURRENT_JUMP_ID(jump.id)"
            >
                <div :class="$style.jumpName">
                    {{ jump.name }}
                </div>
                <div v-if="jump.status.length >= 2" :class="$style.status">
                    <div
                        v-for="(statu, key) in jump.status"
                        :key="`statu${key}`"
                        :class="[$style.statu, statu > 0 ? $style.statuBad : '']"
                    />
                </div>
            </div>
        </div>
    </div>
</template>

<style lang="scss" module>
@import '@design';

.wrapper {
    margin-top: 20px;
}
// TITLE
.title {
    margin-bottom: 5px;
    font-size: 0.75em;
    font-weight: 600;
    text-transform: uppercase;
    opacity: 0.8;
}
// JUMPS
.jumps {
    display: flex;
    justify-content: space-between;
    background: var(--color-background-tertiary);
}
.jump {
    @include unselectable;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 20px;
    height: 20px;
    margin: 10px;

    cursor: default;
    background: transparent;
    border-radius: $border-radius-round;
    box-shadow: 0 0 0 1px white;
}
.jumpActive {
    color: black;
    background: white;
    box-shadow: 0 0 0 0 rgba(white, 0.5);
    animation: pulse 1.5s ease-out forwards infinite;
    &.jumpStatusBad {
        box-shadow: 0 0 0 0 var(--color-red-light);
    }
    &.jumpStatusGood {
        box-shadow: 0 0 0 0 var(--color-green-light);
    }
}
.jumpStatusGood {
    color: black;
    background: var(--color-green);
    box-shadow: 0 0 0 5px var(--color-green-lighter);
}
.jumpStatusBad {
    color: black;
    background: var(--color-red);
    box-shadow: 0 0 0 5px var(--color-red-lighter);
}

@keyframes pulse {
    to {
        box-shadow: 0 0 0 15px rgba(white, 0);
    }
}
.jumpName {
    font-family: var(--font-family)-heading;
    font-size: 0.7em;
    font-weight: 900;
}
.status {
    position: absolute;
    bottom: -8px;
    display: flex;
}
.statu {
    width: 4px;
    height: 4px;
    margin: 1px;
    background: var(--color-green);
    border-radius: $border-radius-round;
}
.statuBad {
    background: var(--color-red);
}
</style>
