import Vue from 'vue'
import VueRouter from 'vue-router'
import NProgress from 'nprogress/nprogress'
import store from '@state/store'
import routes from './routes'

NProgress.configure({
    showSpinner: false,
})

Vue.use(VueRouter)

const router = new VueRouter({
    routes,
    mode: 'history',
    scrollBehavior(to, from, savedPosition) {
        if (savedPosition) {
            return savedPosition
        } else {
            return {
                x: 0,
                y: 0,
            }
        }
    },
})

router.beforeEach((routeTo, routeFrom, next) => {
    // If this isn't an initial page load...
    if (routeFrom.name !== null) {
        // Start the route progress bar.
        NProgress.start()
    }

    const authRequired = routeTo.matched.some((route) => route.meta.authRequired)

    if (!authRequired) return next()

    store.getters['auth/loggedIn'] ? next() : redirectToLogin()

    function redirectToLogin() {
        next({
            name: 'login',
            query: {
                redirectFrom: routeTo.fullPath,
            },
        })
    }
})

router.beforeResolve(async (routeTo, routeFrom, next) => {
    // Create a `beforeResolve` hook, which fires whenever
    // `beforeRouteEnter` and `beforeRouteUpdate` would. This
    // allows us to ensure data is fetched even when params change,
    // but the resolved route does not. We put it in `meta` to
    // indicate that it's a hook we created, rather than part of
    // Vue Router (yet?).
    try {
        // For each matched route...
        for (const route of routeTo.matched) {
            await new Promise((resolve, reject) => {
                // If a `beforeResolve` hook is defined, call it with
                // the same arguments as the `beforeEnter` hook.
                if (route.meta && route.meta.beforeResolve) {
                    route.meta.beforeResolve(routeTo, routeFrom, (...args) => {
                        // If the user chose to redirect...
                        if (args.length) {
                            // If redirecting to the same route we're coming from...
                            if (routeFrom.name === args[0].name) {
                                // Complete the animation of the route progress bar.
                                NProgress.done()
                            }
                            // Complete the redirect.
                            next(...args)
                            reject(new Error('Redirected'))
                        } else {
                            resolve()
                        }
                    })
                } else {
                    // Otherwise, continue resolving the route.
                    resolve()
                }
            })
        }
        // If a `beforeResolve` hook chose to redirect, just return.
    } catch (error) {
        return
    }

    // If we reach this point, continue resolving the route.
    next()
})

router.afterEach((routeTo, routeFrom) => {
    NProgress.done()
})

export default router
