import Vue from 'vue'
import VueI18n from 'vue-i18n'

Vue.use(VueI18n)

export const i18n = new VueI18n({
    locale: undefined, // set locale
    fallbackLocale: 'en',
})

const loadedLanguages = [] // our default language that is preloaded

const setI18nLanguage = (lang) => {
    i18n.locale = lang
    // axios.defaults.headers.common['Accept-Language'] = lang
    Vue.config.lang = lang
    localStorage.locale = lang
    document.querySelector('html').setAttribute('lang', lang)

    return lang
}

export const loadTranslations = async (lang) => {
    // If the same language OR the language was already loaded
    if (i18n.locale === lang || loadedLanguages.includes(lang)) {
        return Promise.resolve(setI18nLanguage(lang))
    }

    const translationFile = require(`../../public/locales/${lang}.json`)

    i18n.setLocaleMessage(lang, translationFile)
    loadedLanguages.push(lang)

    return setI18nLanguage(lang)
}
