export default (number, decimals = 2, removeZeros = false) => {
    if (typeof number !== 'number') {
        return number
    } else {
        let result = Math.round(number * 100) / 100

        return decimals === false
            ? result
            : removeZeros
            ? parseFloat(result.toFixed(decimals))
            : result.toFixed(decimals)
    }
}
